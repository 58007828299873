<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    :width="widthPX"
    :height="heightPX"
    viewBox="0 0 492 491.9"
  >
    <g id="Symbol">
      <g>
        <path
          d="M96.1,23.2A120.63,120.63,0,0,0,52.5,51.6,120.27,120.27,0,0,0,24.1,95.1c-6.1,15.6-10.2,33.5-11.4,59.7s-1.5,34.6-1.5,101.4.3,75.2,1.5,101.4,5.4,44.1,11.4,59.7a120.63,120.63,0,0,0,28.4,43.6,120.63,120.63,0,0,0,43.6,28.4c15.6,6.1,33.5,10.2,59.7,11.4s34.6,1.5,101.4,1.5,75.2-.3,101.4-1.5,44.1-5.4,59.7-11.4a126,126,0,0,0,72-72c6.1-15.6,10.2-33.5,11.4-59.7s1.5-34.6,1.5-101.4-.3-75.2-1.5-101.4-5.4-44.1-11.4-59.7A121.29,121.29,0,0,0,462,51.6a120.63,120.63,0,0,0-43.6-28.4c-15.6-6.1-33.5-10.2-59.7-11.4s-34.6-1.5-101.4-1.5-75.2.3-101.4,1.5S111.7,17.1,96.1,23.2ZM356.6,56c24,1.1,37,5.1,45.7,8.5a76.63,76.63,0,0,1,28.3,18.4A76.63,76.63,0,0,1,449,111.2c3.4,8.7,7.4,21.7,8.5,45.7,1.2,25.9,1.4,33.7,1.4,99.4s-.3,73.5-1.4,99.4c-1.1,24-5.1,37-8.5,45.7a81.39,81.39,0,0,1-46.7,46.7c-8.7,3.4-21.7,7.4-45.7,8.5-25.9,1.2-33.7,1.4-99.4,1.4s-73.5-.3-99.4-1.4c-24-1.1-37-5.1-45.7-8.5a76.63,76.63,0,0,1-28.3-18.4,76.63,76.63,0,0,1-18.4-28.3c-3.4-8.7-7.4-21.7-8.5-45.7-1.2-25.9-1.4-33.7-1.4-99.4s.3-73.5,1.4-99.4c1.1-24,5.1-37,8.5-45.7A76.63,76.63,0,0,1,83.8,82.9a76.63,76.63,0,0,1,28.3-18.4c8.7-3.4,21.7-7.4,45.7-8.5,25.9-1.2,33.7-1.4,99.4-1.4S330.7,54.8,356.6,56Z"
          transform="translate(-11.2 -10.3)"
          :fill="colorComputed"
        />
        <path
          d="M130.9,256.3A126.3,126.3,0,1,0,257.2,130,126.3,126.3,0,0,0,130.9,256.3Zm208.3,0a82,82,0,1,1-82-82A82,82,0,0,1,339.2,256.3Z"
          transform="translate(-11.2 -10.3)"
          :fill="colorComputed"
        />
        <circle cx="377.4" cy="114.7" r="29.5" :fill="colorComputed" />
      </g>
    </g>
  </svg>
  <!--  <svg-->
  <!--    viewBox="0 0 512 512"-->
  <!--    y="0px"-->
  <!--    x="0px"-->
  <!--    version="1.1"-->
  <!--    :style="{ height: heightPX, width: widthPX }"-->
  <!--    :fill="colorComputed"-->
  <!--  >-->
  <!--    <path-->
  <!--      d="m382 0h-146c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h146c60.652344 0 110 49.34375 110 110v252c0 60.65625-49.347656 110-110 110h-252c-60.652344 0-110-49.34375-110-110v-252c0-60.65625 49.347656-110 110-110h26c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-26c-71.683594 0-130 58.316406-130 130v252c0 71.683594 58.316406 130 130 130h252c71.683594 0 130-58.316406 130-130v-252c0-71.683594-58.316406-130-130-130zm0 0"-->
  <!--    />-->
  <!--    <path-->
  <!--      d="m356 472h26c49.625 0 90-40.375 90-90v-252c0-49.625-40.375-90-90-90h-252c-49.625 0-90 40.375-90 90v252c0 49.625 40.375 90 90 90h146c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-146c-38.597656 0-70-31.402344-70-70v-252c0-38.597656 31.402344-70 70-70h252c38.597656 0 70 31.402344 70 70v252c0 38.597656-31.402344 70-70 70h-26c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10zm0 0"-->
  <!--    />-->
  <!--    <path-->
  <!--      d="m366 256c0-60.652344-49.347656-110-110-110s-110 49.347656-110 110 49.347656 110 110 110 110-49.347656 110-110zm-110 90c-49.625 0-90-40.375-90-90s40.375-90 90-90 90 40.375 90 90-40.375 90-90 90zm0 0"-->
  <!--    />-->
  <!--    <path-->
  <!--      d="m256 186c-38.597656 0-70 31.402344-70 70s31.402344 70 70 70 70-31.402344 70-70-31.402344-70-70-70zm0 120c-27.570312 0-50-22.429688-50-50s22.429688-50 50-50 50 22.429688 50 50-22.429688 50-50 50zm0 0"-->
  <!--    />-->
  <!--    <path-->
  <!--      d="m422 130.003906c0-22.058594-17.945312-40-40-40-22.058594 0-40 17.941406-40 40 0 22.054688 17.941406 40 40 40 22.054688 0 40-17.945312 40-40zm-60 0c0-11.03125 8.972656-20 20-20s20 8.96875 20 20c0 11.027344-8.972656 20-20 20s-20-8.972656-20-20zm0 0"-->
  <!--    />-->
  <!--    <path-->
  <!--      d="m196.003906 20c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-.007812c-5.519532 0-9.996094 4.476562-9.996094 10s4.480469 10 10.003906 10zm0 0"-->
  <!--    />-->
  <!--    <path-->
  <!--      d="m315.996094 452c-5.523438 0-9.996094 4.476562-9.996094 10s4.480469 10 10.003906 10c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm0 0"-->
  <!--    />-->
  <!--  </svg>-->
</template>

<script>
export default {
  name: 'IconInstagram',
  props: {
    height: { type: Number, default: 24 },
    width: { type: Number, default: 24 },
    color: { type: String, default: '#FFFFFF' }
  },
  computed: {
    heightPX() {
      return `${this.height}px`;
    },
    widthPX() {
      return `${this.width}px`;
    },
    colorComputed() {
      return this.color;
    }
  }
};
</script>

<style lang="scss">
.icon {
  margin: 0 4px;
}
</style>
