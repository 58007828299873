<template>
  <img :src="url" class="account-photo" alt="" sizes="50px" />
</template>

<script>
export default {
  name: 'AccountPhoto',
  props: {
    url: { type: String, default: '' }
  }
};
</script>

<style lang="scss">
.account-photo {
  max-width: 100%;
  border-radius: 50%;
}
</style>
