<template>
  <div>
    <div class="form-group mb-0">
      <label v-if="title">{{ title }}</label>
      <div class="input-group">
        <input
          type="text"
          @keyup.13="addItem(newItem)"
          :name="name"
          class="form-control"
          :placeholder="placeholder"
          :disabled="isDisable"
          v-validate="validate"
          data-vv-validate-on="change"
          v-model="newItem"
          v-bind="$attrs"
          :class="{ input: true, 'is-danger': errors.has(name) }"
        />
        <span class="input-group-btn">
          <WButton success
            customClass="mb-0"
            @click="addItem(newItem)"
            :disabled="newItem === ''"
          >
            {{ $t('general.add') }}
          </WButton>
        </span>
      </div>
      <span
        v-tooltip.top-center="{
          content: errors.first(name)
        }"
        v-show="errors.has(name)"
        class="help is-danger error_tooltip"
      >
        <i class="fa fa-warning text-error"></i>
      </span>
    </div>

    <div>
      <ul class="list-unstyled d-flex flex-wrap">
        <li :key="key" v-for="(item, key) in list" class="">
          <h5 class="m-0">
            <div class="badge badge-warning m-h p-q font-weight-normal">
              {{ isArray ? item : key }}
              <WButton warning
                customClass="m-0 p-0"
                @click="removeItem(key)"
              >
                <i class="fa fa-remove text-danger p-q"></i>
              </WButton>
            </div>
          </h5>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'addList',
  inject: ['$validator'],
  inheritAttrs: false,
  props: {
    name: {
      type: String
    },
    list: {
      type: [Object, Array]
    },
    typeList: {
      type: String
    },
    title: {
      type: String
    },
    validate: {
      type: String
    },
    placeholder: {
      type: String
    },
    isDisable: {
      type: Boolean
    }
  },
  data() {
    return {
      newItem: ''
    };
  },
  watch: {},
  computed: {
    isArray() {
      return this.typeList === 'array';
    }
  },
  methods: {
    addItem() {
      this.$validator.validateAll({ [this.name]: this.newItem }).then((result) => {
        if (result) {
          if (this.isArray && !this.list.includes(this.newItem)) {
            this.list.push(this.newItem);
          } else if (!this.isArray && !this.list[this.newItem]) {
            Vue.set(this.list, this.newItem, true);
          }
          this.newItem = '';
          this.$validator.reset();
        }
      });
    },
    removeItem(item) {
      // if (this.isDisable) {
      //   return;
      // }

      if (this.isArray) {
        // было const index = this.list.indexOf(item);
        const index = item;
        this.list.splice(index, 1);
      } else {
        Vue.delete(this.list, item);
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped></style>
