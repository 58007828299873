<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12 d-flex flex-column">
      <WButton info
        customClass="ml-auto"
        v-if="!isDisable"
        @click="openModal(true)"
      >
        {{ $t('userAccount.add') }}
      </WButton>
      <br />
      <Table-pagination-v3-for-accounts
        v-if="showTablePaginationV3Component"
        small-height-for-custom-multiselect-and-select-components
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :showSlotForProfileSelctor="false"
        :show-filters="[
          'search',
          'sort',
          'sex',
          'profile',
          'location',
          'birthday',
          'social',
          'visits',
          'limit',
          'city',
          'home_town',
          'subscription'
        ]"
        :options="tablePaginationOptions"
        :q="filters.q"
      ></Table-pagination-v3-for-accounts>
                    <div class="col-lg-4 pl-0">
                <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <WButton secondary outline sm
                      v-if="isFiltersActive"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </WButton>
                  </div>
                </div>
              </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <!--        <div class="loader loader-backdrop loader-backdrop-portaluseraccounts" v-if="isUserAccountsLoading"></div>-->
        <div v-if="isUserAccountsLoading"><Loader-spinner /></div>
        <div class="card-header d-flex align-items-center justify-content-between">
          <div>
            <i class="fa fa-users mr-0"></i>
            {{ $t('userAccount.title') }}
            <span class="text-muted ml-3" v-if="userAccountsList.length">
              {{ this.userAccountsList.length }} {{ this.$t('portal.paginationOf') }} {{ this.totalAccountsResult }}
            </span>
          </div>
          <div class="d-flex align-items-center">
            <TableDataDownloader
              v-if="!isUserAccountsLoading && userAccountsList && userAccountsList.length > 0"
              class="ml-h"
              :data="formatPortalUserAccounts()"
              :data-for-excel="formatPortalUserAccountToExcel()"
              :filename="getFileNameForDownloadFiles($t('downloadFilesNames.portalAccounts'))"
              :availableReports="{
                csv: true,
                xlsx: {
                  enabled: true,
                  autoColumnsWidth: true
                },
                pdf: true
              }"
            />
          </div>
        </div>
        <div class="card-block card-block__table table-responsive">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
            <div class="col-lg-12"> -->
          <table class="table table-hover">
            <thead>
              <tr>
                <th style="width: 50px;">№</th>
                <th scope="col" v-if="!isBeelineBranding" class="text-nowrap align-middle">{{ $t('userAccount.username') }}</th>
                <th style="width: 50px;"></th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.name') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.profile') }}</th>
                <th scope="col" v-if="!isBeelineBranding" class="text-nowrap align-middle">{{ $t('general.mac') }}</th>
                <th scope="col" class="text-nowrap align-middle" style="width: 50px;">
                  {{ $t('userAccount.visits') }}
                </th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.socialLinks') }}</th>

                <!-- <th v-if="showBalanceAndTransactions" scope="col" class="text-nowrap align-middle">
                  {{ $t('userAccount.balance') }}
                </th> -->
                <th scope="col" class="text-nowrap align-middle" style="width: 150px;">
                  {{ $t('userAccount.subscriptionTableTitle') }}
                </th>
                <th scope="col" v-if="!isBeelineBranding" class="text-nowrap align-middle text-center" style="width: 100px;">
                  {{ $t('portal.authentication') }}
                </th>
                <th scope="col" class="text-nowrap align-middle text-center" style="width: 100px;">
                  {{ $t('portal.authorization') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in userAccountsList" :key="account.id" @click="openModal(false, account)">
                <td class="align-middle text-center">{{ index + 1 }}</td>
                <td v-if="!isBeelineBranding" class="align-middle">
                  <span v-if="account.identity">
                    <i
                      v-if="account.sex"
                      class="fa"
                      :class="{ 'fa-venus': account.sex === 'female', 'fa-mars': account.sex === 'male' }"
                      aria-hidden="true"
                      style="margin-left: -0.85rem;"
                    ></i>
                    {{ account.identity }}
                  </span>
                  <Empty-cell-value v-else />
                </td>
                <td class="align-middle p-q">
                  <Account-photo v-if="account.photo_url" :url="account.photo_url" />
                </td>
                <td class="align-middle">
                  <span v-if="account.name || account.surname">
                    {{ getFullName(account.name, account.surname) }}
                  </span>
                  <Empty-cell-value v-else />
                </td>
                <td class="align-middle">
                  <span>
                    {{ getProfileNameByID(account.profile) }}
                  </span>
                </td>
                <td v-if="!isBeelineBranding" class="align-middle">
                  <h5 v-if="account.macs && account.macs.length">
                    <span class="badge badge-warning font-weight-normal">
                      {{ getFormatMac(account.macs[0]) }}
                    </span>
                    <span
                      class="badge badge-dark font-weight-normal"
                      v-if="account.macs.length > 1"
                      v-tooltip.top-center="{
                        content: showAllFormatMac(account.macs)
                      }"
                    >
                      +{{ account.macs.length - 1 }}
                    </span>
                  </h5>
                  <Empty-cell-value v-else />
                </td>
                <td class="align-middle">
                  <span v-if="account.visits && account.visits.all">
                    {{ account.visits.all }}
                  </span>
                  <Empty-cell-value v-else />
                </td>
                <td class="align-middle">
                  <div v-if="hasAccountSocialNetworks(account)">
                    <!--                    {{extractSocialNetworksNamesFormAccount(account)}}-->
                    <span
                      :title="$t('userAccount.vk')"
                      class="social-networks-icons-in-table"
                      v-if="hasAccountVkSocialNetwork(account)"
                    >
                      <IconVk :height="20" :width="20" :color="'#3785f9'"></IconVk>
                    </span>
                    <span
                      :title="$t('userAccount.fb')"
                      class="social-networks-icons-in-table"
                      v-if="hasAccountFacebookSocialNetwork(account)"
                    >
                      <IconFacebook :height="20" :width="20" :color="'#2a56ac'"></IconFacebook>
                    </span>
                    <span
                      :title="$t('userAccount.instagram')"
                      class="social-networks-icons-in-table"
                      v-if="hasAccountInstagramSocialNetwork(account)"
                    >
                      <IconInstagram :height="20" :width="20" :color="'#f99594'"></IconInstagram>
                    </span>
                  </div>
                  <Empty-cell-value v-else />
                </td>
                <!-- <td v-if="showBalanceAndTransactions" class="align-middle">
                  <span v-if="account.balance !== undefined">
                    {{ account.balance }}
                  </span>
                  <EmptyCellValue v-else />
                </td> -->
                <td class="align-middle">
                  <span v-if="account.subscribe_id" >
                    <div class="badge badge-success font-weight-normal">
                    {{$t('userAccount.subscribed')}}
                    </div>
                    <div class="subscription-info-in-users-table">
<!--                      <b>{{$t('userAccount.subscriptionName')}}:&nbsp;</b>-->
                      <a href="#" class="ml-0"
                         @click.prevent.stop="handleSubscriptionNameClick(account.subscribe_id)">
                      <span v-if="subscriptionObjectById(account.subscribe_id)">{{subscriptionObjectById(account.subscribe_id).name}}</span>
                      <span v-else>{{account.subscribe_id}}</span>
                        </a>
                    </div>
                    <div class="subscription-info-in-users-table"> <span>{{$t('userAccount.subscribedFromDate')}}&nbsp;</span>
                      <span v-if="account.create_at_subscribe">{{formatDateForTable(account.create_at_subscribe)}}</span>
                    </div>
                  </span>
                  <div v-else class="badge badge-default font-weight-normal">
                    {{$t('userAccount.notSubscribed')}}
                    </div>
                </td>
                <td v-if="!isBeelineBranding" class="align-middle text-center">
                  <WButton warning outline
                    customClass="py-0 px-h"
                    v-if="!checkUserInAccesslist(account)"
                    @click.stop="addToAccessList(account.profile, account.id)"
                    v-tooltip="{ content: $t('userAccount.generateAccessListCode') }"
                  >
                    <i class="glyphicons glyphicons-plus" />
                  </WButton>
                  <WButton info outline
                    customClass="py-0 px-h"
                    v-if="checkUserInAccesslist(account)"
                    @click.stop="deleteFromAccessList(account.profile, account.id)"
                    v-tooltip="{ content: $t('userAccount.deleteAccessListCode') }"
                  >
                    <i class="glyphicons glyphicons-minus" />
                  </WButton>
                </td>
                <td class="align-middle text-center">
                  <WButton success outline
                    customClass="py-0 px-h"
                    v-if="!checkUserInBlacklist(account)"
                    @click.stop="addToBlackList(account.profile, account.id)"
                    v-tooltip="{ content: $t('userAccount.addToBlacklist') }"
                  >
                    <i class="glyphicons glyphicons-plus" />
                  </WButton>
                  <WButton danger outline
                    customClass="py-0 px-h"
                    v-if="checkUserInBlacklist(account)"
                    @click.stop="deleteFromBlackList(account.profile, account.id)"
                    v-tooltip="{ content: $t('userAccount.deleteFromBlacklist') }"
                  >
                    <i class="glyphicons glyphicons-minus" />
                  </WButton>
                </td>
              </tr>
              <tr v-if="!userAccountsList.length">
                <td colspan="10" class="no-data-row">
                  <span>{{ $t('aps.noDataToDisplay') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>
          </div> -->
          <div
            v-if="
              this.canLoadMoreAccounts &&
              this.userAccountsList.length &&
              this.totalAccountsResult - this.userAccountsList.length
            "
            class="card-block text-center p-0"
          >
            <WButton fullWidth
              @click="loadMore"
            >
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalAccountsResult - this.userAccountsList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalAccountsResult - this.userAccountsList.length }}</span>
              {{ this.$t('portal.paginationUserAccountsOutOf') }}
              {{ this.totalAccountsResult - this.userAccountsList.length }}
            </WButton>
          </div>
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <Modal
      :backdrop="false"
      :title="isNew ? this.$t('userAccount.new') : this.$t('userAccount.edit')"
      @click.native="handleModalClick"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
      xlarge
    >
      <div slot="modal-title" class="modal-title">
        <h4>{{ isNew ? $t('userAccount.new') : $t('userAccount.edit') }}</h4>
        <h6 v-if="!isNew">{{ $t('userAccount.createAt') }} {{ moment(account.create_at * 1000).format('llll') }}</h6>
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group half-width">
          <label for="profile" class="required-mark">{{ $t('userAccount.profile') }}</label>
          <select
            id="profile"
            name="profile"
            class="form-control input"
            :disabled="isDisable"
            v-model.trim="account.profile"
            v-validate="'required'"
          >
            <option v-for="profile in portalProfilesList" :key="profile.id" :value="profile.id">
              {{ profile.name }}
            </option>
          </select>
          <span
            v-tooltip.top-center="{ content: errors.first('profile') }"
            v-show="errors.has('profile')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>

        <div v-if="showBalanceAndTransactions" class="form-group half-width">
          <label for="balance">
            {{ $t('userAccount.balance') }}
            <info :content="$t('userAccount.balanceInfo')" />
          </label>
          <input
            id="balance"
            name="balance"
            type="number"
            class="form-control input"
            :readonly="isDisable"
            v-model.trim.number="account.balance"
            v-validate="'min_value:0|integer'"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('balance') }"
            v-show="errors.has('balance')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group half-width">
          <label for="firstName" class="">{{ $t('userAccount.firstName') }}</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            class="form-control input"
            :readonly="isDisable"
            v-model.trim="account.name"
            v-validate=""
          />
          <span
            v-tooltip.top-center="{ content: errors.first('firstName') }"
            v-show="errors.has('firstName')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>
        <div class="form-group half-width">
          <label for="surname" class="">{{ $t('userAccount.surname') }}</label>
          <input
            id="surname"
            name="surname"
            type="text"
            class="form-control input"
            :readonly="isDisable"
            v-model.trim="account.surname"
            v-validate=""
          />
          <span
            v-tooltip.top-center="{ content: errors.first('surname') }"
            v-show="errors.has('surname')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="form-group half-width">
          <label for="identity" class="required-mark">
            {{ $t('userAccount.username') }}
            <info :content="$t('userAccount.identityInfo')" />
          </label>
          <input
            id="identity"
            name="identity"
            type="text"
            class="form-control input"
            :readonly="isDisable"
            v-model.trim="account.identity"
            v-validate="'required'"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('identity') }"
            v-show="errors.has('identity')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>
        <div class="form-group half-width">
          <label for="identity" class="">{{ $t('userAccount.gender.type') }}</label>
          <div class="cool-modern-selector">
            <input class="form-check-input" type="radio" id="gender-male" v-model="account.sex" value="male" />
            <label class="form-check-label mr-q" for="gender-male">{{ $t('userAccount.gender.male') }}</label>
            <input class="form-check-input" type="radio" id="gender-female" v-model="account.sex" value="female" />
            <label class="form-check-label mr-q" for="gender-female">{{ $t('userAccount.gender.female') }}</label>
            <input class="form-check-input" type="radio" id="gender-unset" v-model="account.sex" value="" />
            <label class="form-check-label mr-q" for="gender-unset">{{ $t('userAccount.gender.unset') }}</label>
          </div>
        </div>
      </div>

      <div v-if="account.authentication_code" class="d-flex justify-content-between">
        <div class="form-group half-width">
          <label for="identity" class="">
            {{ $t('userAccount.authCode') }}
            <info :content="$t('userAccount.authCodeInfo')" />
          </label>
          <div class="d-flex">
            <input
              id="authCode"
              name="authCode"
              type="text"
              class="form-control input"
              v-model.trim="account.authentication_code"
              readonly
            />
            <WButton success
              customClass="button-after-input-field-or-select-field-in-same-string"
              v-clipboard:copy="account.authentication_code"
              v-tooltip.top-center="{
                content: $t('general.copy')
              }"
            >
              <i class="fa fa-lg fa-clone"></i>
            </WButton>
          </div>

          <span
            v-tooltip.top-center="{ content: errors.first('authCode') }"
            v-show="errors.has('authCode')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="form-group half-width">
          <label for="identity" class="">{{ $t('userAccount.birthday') }}</label>
          <Datepicker
            id="birthday"
            name="birthday"
            value-type="format"
            v-model="account.date_of_birth"
            placeholder="01-01-2000"
            :editable="false"
            format="DD-MM-YYYY"
            v-validate="'date_format'"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('birthday') }"
            v-show="errors.has('birthday')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>
        <div class="form-group half-width">
          <AddList
            name="macs"
            :list="account.macs"
            :title="$t('userAccount.macs')"
            :validate="'mac'"
            :placeholder="'aa:aa:aa:aa:aa:aa'"
            :typeList="'array'"
            :class="{ 'is-danger': errors.has('macs') }"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group half-width">
          <label :for="'city'" class="">{{ $t('userAccount.city') }}</label>
          <input :name="'city'" :id="'city'" v-model.trim="account.city" class="form-control input text-center" />
          <span
            v-tooltip.top-center="{ content: errors.first('city') }"
            v-show="errors.has('city')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>
        <div class="form-group half-width">
          <label for="home-town" class="">{{ $t('userAccount.homeTown') }}</label>
          <input
            name="home-town"
            id="home-town"
            v-model.trim="account.home_town"
            class="form-control input text-center"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('home-town') }"
            v-show="errors.has('home-town')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>
      </div>
      <!-- Social data -->
      <h6 v-if="account.social_network && Object.keys(account.social_network).length > 0" class="my-2">
        {{ $t('userAccount.dataFromSocials') }}
      </h6>
      <div v-for="(social, index) in account.social_network" :key="index" class="interface-block mb-1">
        <div class="setting-block-header" @click.prevent="toggleShowSocialNetworksBlock(index)">
          <div class="setting-block-status">
            <p class="h6 m-h pr-h setting-block-title">{{ $t(`portalStats.auth.${index}`) }}</p>
          </div>
          <div class="setting-block-buttons">
            <WButton link>
              <i class="icon-arrow-down" v-if="show[index]"></i>
              <i class="icon-arrow-left" v-else></i>
            </WButton>
          </div>
        </div>
        <div v-if="show[index]">
          <!-- <transition name="interface"> -->
          <div class="d-flex justify-content-between mt-1">
            <div class="form-group half-width">
              <label for="firstName" class="">{{ $t('userAccount.firstName') }}</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                class="form-control input"
                v-model.trim="social.first_name"
                v-validate=""
                readonly
              />
              <span
                v-tooltip.top-center="{ content: errors.first('firstName') }"
                v-show="errors.has('firstName')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
            <div class="form-group half-width">
              <label for="surname" class="">{{ $t('userAccount.surname') }}</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                class="form-control input"
                readonly
                v-model.trim="social.last_name"
                v-validate=""
              />
              <span
                v-tooltip.top-center="{ content: errors.first('lastName') }"
                v-show="errors.has('lastName')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="form-group half-width">
              <label for="email" class="required-mark">{{ $t('userAccount.email') }}</label>
              <input
                id="email"
                name="email"
                type="email"
                class="form-control input"
                readonly
                v-model.trim="social.email"
                v-validate=""
              />
              <span
                v-tooltip.top-center="{ content: errors.first('identity') }"
                v-show="errors.has('identity')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
            <div class="form-group half-width">
              <label for="identity" class="">{{ $t('userAccount.gender.type') }}</label>
              <div>
                <Checkbox-component
                  v-for="type of GENDERS"
                  class="mr-2"
                  :id="`${type}-gender`"
                  :value="type === social.sex"
                  :label="$t(`userAccount.gender.${type.toLowerCase()}`)"
                  :key="type"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="form-group half-width">
              <label :for="`${index}-birthday`" class="">{{ $t('userAccount.birthday') }}</label>
              <input
                :name="`${index}-birthday`"
                :id="`${index}-birthday`"
                v-model.trim="social.birthday"
                readonly
                class="form-control input text-center"
              />
              <span
                v-tooltip.top-center="{ content: errors.first(`${index}-birthday`) }"
                v-show="errors.has(`${index}-birthday`)"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
            <div class="form-group half-width">
              <label :for="`${index}-link`" class="">{{ $t('userAccount.socialLink') }}</label>
              <div class="d-flex">
                <input
                  :name="`${index}-link`"
                  :id="`${index}-link`"
                  :value="createSocialLink(social.id, index)"
                  readonly
                  class="form-control input"
                />
                <!--                <form :action="createSocialLink(social.id, index)" target="_blank" rel="noopener noreferrer">-->
                <!--                  <button-->
                <!--                    class="btn btn-primary"-->
                <!--                    v-tooltip.top-center="{-->
                <!--                      content: $t('userAccount.goToLink', { link: createSocialLink(social.id, index) })-->
                <!--                    }"-->
                <!--                  >-->
                <!--                    <i class="fa fa-lg" :class="{ 'fa-vk': index === 'vk', 'fa-facebook-square': index === 'fb', 'fa-facebook-square': index === 'facebook' }"></i>-->
                <!--                  </button>-->
                <!--                </form>-->
                <div>
                  <WButton link
                    @click="openSocialNetworkLink(createSocialLink(social.id, index))"
                    v-tooltip.top-center="{
                      content: $t('userAccount.goToLink', { link: createSocialLink(social.id, index) })
                    }"
                  >
                    <i class="fa fa-lg" :class="{ 'fa-vk': index === 'vk', 'fa-facebook-square': index === 'fb' || index === 'facebook' }"></i>
                  </WButton>
                </div>
              </div>
            </div>
          </div>
          <div v-if="social.home_town || social.city" class="d-flex justify-content-between">
            <div v-if="social.city" class="form-group half-width">
              <label :for="`${index}-city`" class="">{{ $t('userAccount.city') }}</label>
              <input
                :name="`${index}-city`"
                :id="`${index}-city`"
                v-model.trim="social.city"
                readonly
                class="form-control input text-center"
              />
              <span
                v-tooltip.top-center="{ content: errors.first(`${index}-city`) }"
                v-show="errors.has(`${index}-city`)"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
            <div v-if="social.home_town" class="form-group half-width">
              <label :for="`${index}-home-town`" class="">{{ $t('userAccount.homeTown') }}</label>
              <input
                :name="`${index}-home-town`"
                :id="`${index}-home-town`"
                v-model.trim="social.home_town"
                readonly
                class="form-control input text-center"
              />
              <span
                v-tooltip.top-center="{ content: errors.first(`${index}-home-town`) }"
                v-show="errors.has(`${index}-home-town`)"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
          </div>
          <div v-if="social.universities && social.universities.length" class="form-group">
            <label :for="`${index}-universities`" class="">{{ $t('userAccount.universities') }}</label>
            <ul class="list-unstyled d-flex flex-wrap">
              <li :key="key" v-for="(university, key) in social.universities" class="">
                <h5 class="m-0">
                  <div class="badge badge-info m-h p-q font-weight-normal">
                    {{ university }}
                  </div>
                </h5>
              </li>
            </ul>
            <span
              v-tooltip.top-center="{ content: errors.first(`${index}-universities`) }"
              v-show="errors.has(`${index}-universities`)"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error"></i>
            </span>
          </div>
        </div>
      </div>

      <account-sessions v-if="!isNew && isModalOpen" :account="account" :key="`sessions-${account.id}`" />

      <account-transactions
        v-if="showBalanceAndTransactions && !isNew && isModalOpen"
        :account="account"
        :key="`transactions-${account.id}`"
      />

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton success outline
          customClass="mr-1"
          v-if="!isDisable"
          @click="handleSave"
          :disabled="errors.any() || muteEdit"
        >
          <span :class="{ invisible: muteEdit }">{{ isNew ? $t('general.create') : $t('general.save') }}</span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </WButton>
        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNew && !isDisable"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="cancelEditMode"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </Modal>

    <Delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="handleCloseDeleteDialog.bind(this)"
      :handle-delete="deleteUserAccount.bind(this)"
      :target-text="account.identity"
    />

    <Close-dialog
      :is-open="isCloseModalOpen"
      :handle-yes="handleConfirmClose.bind(this)"
      :handle-no="closeConfirmModal.bind(this)"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Datepicker from 'vue2-datepicker';
import { mask } from 'vue-the-mask';
import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
// import SelectComponent from '../../components/Universal/select/select-component.vue';
import AddList from '../../components/UI/AddList.vue';
import CloseDialog from '../../components/close-dialog.vue';
import DeleteDialog from '../../components/delete-dialog.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import AccountSessions from '../../components/UI/account-sessions.vue';
import AccountTransactions from '../../components/UI/account-transactions.vue';
import helpers from '../../helpers';
import Modal from '../../components/Modal.vue';
import portalProfilesService from '../../services/portalProfilesService';
import locationService from '../../services/locationService';
import portalUserAccountsService from '../../services/portalUserAccountsService';
// import TablePaginationV2 from '../../components/table-pagination-v2.vue';
import TablePaginationV3ForAccounts from '../../components/table-pagination-v3-for-accounts.vue';
import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';
import Info from '../../components/Universal/info-icon.vue';
import AccountPhoto from '../../components/Universal/account-photo/account-photo.vue';
import IconVk from '../../components/SocialNetworkIcons/Icon-vk.vue';
import IconFacebook from '../../components/SocialNetworkIcons/Icon-facebook.vue';
import IconInstagram from '../../components/SocialNetworkIcons/Icon-instagram.vue';
import portalTariffsService from '../../services/portalTariffsService';
import moment from "moment";

const watcher = new helpers.ChangesWatcher();

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

const GENDERS = ['male', 'female', 'unset'];
const SOCIALS = ['vk', 'facebook', 'instagram', 'unset'];

export default {
  name: 'UserAccounts',
  components: {
    AccountPhoto,
    Modal,
    EmptyCellValue,
    // TablePaginationV2,
    TablePaginationV3ForAccounts,
    DeleteDialog,
    CloseDialog,
    Datepicker,
    AddList,
    MultiSelectComponent,
    // SelectComponent,
    AccountTransactions,
    AccountSessions,
    CheckboxComponent,
    Info,
    IconVk,
    IconFacebook,
    IconInstagram
  },
  directives: { mask },
  data() {
    return {
      isGetSubscriptionsFromUserAccountsLoading: false,
      showTablePaginationV3Component: true,
      // тут храним информацию о существующих вариантах подписок и тарифов
      subscriptionsAndTariffsList: [],
      selectedProfileInPortalUserAccounts: { name: this.$t('portalStats.allProfiles'), id: '' },
      selectedLocationInPortalUserAccounts: { name: this.$t('portalStats.allLocations'), id: '' },
      isShow: false,
      showTransactions: false,
      show: {
        vk: false,
        facebook: false,
        fb: false,
        instagram: false
      },
      offset: 0,
      canLoadMoreAccounts: true,
      totalAccountsResult: false,
      isModalOpen: false,
      isCloseModalOpen: false,
      isDeleteOpen: false,
      isNew: true,
      account: {} /* setting initial value to false breaks something */,
      newAccount: {
        profile: '',
        identity: '',
        balance: 0,
        name: '',
        surname: '',
        id: '',
        date_of_birth: ''
      },
      // selectedProfileInMultiselectForRequest: [],
      selectedProfileInMultiselect: [],
      visitsGreaterLessOperator: '$gt',
      filterLocation: {
        location: {},
        with_childs: true
      },
      filters: {
        limit: 30,
        offset: 0,
        sort_by: 'profile',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        profiles: [],
        location: {
          data: {},
          with_childs: true
        },
        q: {
          city: '',
          home_town: '',
          sex: '',
          social_network: '',
          'date_of_birth_struct.year': '',
          'visits.all': {},
          subscribe_id: 'unset'
        }
      }
    };
  },
  methods: {
    handleSubscriptionNameClick(subscriptionId) {
      // console.log(subscriptionId)
      const clickedSubscription = this.subscriptionObjectById(subscriptionId)
      if (clickedSubscription) {
        // console.log(clickedSubscription)
        this.$router.push({
          name: 'Tariffs',
          params: { subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam: JSON.parse(JSON.stringify(clickedSubscription)) }
        });
      }
    },
    // resetAllFilters() {
    //   this.filters =  {
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'profile',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     profiles: [],
    //     location: {
    //       data: {},
    //       with_childs: true
    //     },
    //     q: {
    //       city: '',
    //       home_town: '',
    //       sex: '',
    //       social_network: '',
    //       'date_of_birth_struct.year': '',
    //       'visits.all': {},
    //       subscribe_id: 'unset'
    //     }
    //   }
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    // },
    resetAllFilters() {
      this.filters =  {
        limit: 30,
        offset: 0,
        sort_by: 'profile',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        profiles: [],
        // location: {
        //   data: {},
        //   with_childs: true
        // },
        q: {
          city: '',
          home_town: '',
          sex: '',
          social_network: '',
          'date_of_birth_struct.year': '',
          'visits.all': {},
          subscribe_id: 'unset'
        }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.redrawTablePaginationV3Component();
      this.getWithQuery();
    },
    redrawTablePaginationV3Component(){
      this.showTablePaginationV3Component = false;
      this.$nextTick(()=>{
        this.showTablePaginationV3Component = true;
      })
    },
    formatDateForTable(timestamp) {
      try {
        return moment.unix(timestamp).format('DD.MM.YYYY  H:mm');
      } catch (e) {
        return timestamp;
      }
    },
    subscriptionObjectById(subscriptionId) {
      if (this.subscriptionsListById[subscriptionId]) {
        return this.subscriptionsListById[subscriptionId]
      } else {
        return undefined
      }
    },
    getSubscriptionsAndTariffs() {
      portalTariffsService.getSubscriptionsFromUserAccounts(this)
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    toggleShowSocialNetworksBlock(index) {
      this.show[index] = !this.show[index];
    },
    getStirngForSocialNetwork(socialNetworkName) {
      try {
        if (socialNetworkName === 'vk') {
          return this.$t('userAccount.vk');
        }
        if (socialNetworkName === 'fb' || socialNetworkName === 'facebook') {
          return this.$t('userAccount.fb');
        }
        if (socialNetworkName === 'instagram') {
          return this.$t('userAccount.instagram');
        }
        return socialNetworkName;
      } catch (e) {
        return socialNetworkName;
      }
    },
    getStringForSocialNetworkSex(socialNetwork) {
      // ( `${el}: ${account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'}`
      try {
        if (typeof socialNetwork === 'object' && Object.prototype.hasOwnProperty.call(socialNetwork, 'sex')) {
          if (socialNetwork.sex !== '' && socialNetwork.sex !== '<nil>') {
            return this.$t(`userAccount.gender.${socialNetwork.sex}`);
          }
        }
        return this.$t('userAccount.unset');
      } catch (e) {
        return this.$t('userAccount.unset');
      }
    },
    openSocialNetworkLink(link) {
      window.open(link, '_blank');
    },
    hasAccountVkSocialNetwork(account) {
      try {
        if (this.extractSocialNetworksNamesFormAccount(account).includes('vk')) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    hasAccountFacebookSocialNetwork(account) {
      try {
        if (this.extractSocialNetworksNamesFormAccount(account).includes('facebook')) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    hasAccountInstagramSocialNetwork(account) {
      try {
        if (this.extractSocialNetworksNamesFormAccount(account).includes('instagram')) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    hasAccountSocialNetworks(account) {
      // возвращаем true если для акаунта указаны соц. сети
      try {
        if (this.extractSocialNetworksNamesFormAccount(account).length > 0) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    extractSocialNetworksNamesFormAccount(account) {
      // проверям данные о каких соц сетях есть у акканута и отдаем их список в виде массива
      try {
        const result = [];
        if (
          Object.prototype.hasOwnProperty.call(account, 'social_network') &&
          typeof account.social_network === 'object' &&
          Object.keys(account.social_network).length > 0
        ) {
          if (
            (Object.prototype.hasOwnProperty.call(account.social_network, 'facebook') &&
              typeof account.social_network.facebook === 'object') ||
            (Object.prototype.hasOwnProperty.call(account.social_network, 'fb') &&
              typeof account.social_network.fb === 'object')
          ) {
            result.push('facebook');
          }
          if (
            Object.prototype.hasOwnProperty.call(account.social_network, 'vk') &&
            typeof account.social_network.vk === 'object'
          ) {
            result.push('vk');
          }
          if (
            Object.prototype.hasOwnProperty.call(account.social_network, 'instagram') &&
            typeof account.social_network.instagram === 'object'
          ) {
            result.push('instagram');
          }
        }
        return result;
      } catch (e) {
        return [];
      }
    },
    async requestProfiles(searchQuery) {
      return portalProfilesService.requestProfiles(searchQuery);
    },
    async requestLocations(searchQuery) {
      return locationService.requestLocations(searchQuery);
    },
    toggleWithChilds() {
      this.filterLocation.with_childs = !this.filterLocation.with_childs;
    },
    handleChangeVisitsGreaterLessOperator() {
      if (this.visitsGreaterLessOperator === '$gt') {
        this.visitsGreaterLessOperator = '$lt';
        delete this.filters.q['visits.all'].$gt;
        delete this.filters.q['visits.all'].$eq;
      } else if (this.visitsGreaterLessOperator === '$lt') {
        this.visitsGreaterLessOperator = '$eq';
        delete this.filters.q['visits.all'].$lt;
        delete this.filters.q['visits.all'].$gt;
      } else {
        this.visitsGreaterLessOperator = '$gt';
        delete this.filters.q['visits.all'].$lt;
        delete this.filters.q['visits.all'].$eq;
      }
    },
    showAllFormatMac(macsList) {
      let macs = '';
      macsList.forEach((mac) => {
        macs += `<li><small><b></b> ${this.getFormatMac(mac)}</small></li>`;
      });
      return `<ul class='list-unstyled text-left m-0'>
                                    <li class='mb-q'><b>MAC</b></li>
                                    ${macs}
                                 </ul>`;
    },
    profileSelectedFromSelectorInSlot() {
      // console.log(this.$refs.filterProfile.value);
      const profileId = this.$refs.filterProfile.value;
      if (profileId === '' || profileId === undefined) {
        this.selectedProfileInPortalUserAccounts = { name: this.$t('portalStats.allProfiles'), id: '' };
      } else {
        const selectedProfileName =
          this.portalProfilesListById[profileId] && this.portalProfilesListById[profileId].name ?
            this.portalProfilesListById[profileId].name :
            this.$t('portalStats.allProfiles');
        const selectedProfileId =
          this.portalProfilesListById[profileId] && this.portalProfilesListById[profileId].id ?
            this.portalProfilesListById[profileId].id :
            '';
        this.selectedProfileInPortalUserAccounts = { name: selectedProfileName, id: selectedProfileId };
      }
      // this.getWithQuery();
    },
    locationSelectedFromSelectorInSlot() {
      // console.log(this.$refs.filterProfile.value);
      const locationId = this.$refs.filterLocation.value;
      if (locationId === '' || locationId === undefined) {
        this.selectedLocationInPortalUserAccounts = { name: this.$t('portalStats.allProfiles'), id: '' };
        this.filterLocation.with_childs = true;
      } else {
        const selectedLocationName =
          this.locationsListById[locationId] && this.locationsListById[locationId].name ?
            this.locationsListById[locationId].name :
            this.$t('portalStats.allLocations');
        const selectedLocationId =
          this.locationsListById[locationId] && this.locationsListById[locationId].id ?
            this.locationsListById[locationId].id :
            '';
        this.selectedLocationInPortalUserAccounts = { name: selectedLocationName, id: selectedLocationId };
      }
      // this.getWithQuery();
    },
    profileSelected() {
      this.getWithQuery();
    },
    // formatPortalUserAccounts() {
    //   const result = [];
    //   this.userAccountsList.forEach((account) => {
    //     // for (const account of this.userAccountsList) {
    //     const accountObj = {};
    //     accountObj[this.$t('userAccount.username')] = '';
    //     if (account.identity) {
    //       accountObj[this.$t('userAccount.username')] = account.identity;
    //     }
    //     accountObj[this.$t('userAccount.profile')] = '';
    //     if (this.getProfileNameByID(account.profile)) {
    //       accountObj[this.$t('userAccount.profile')] = this.getProfileNameByID(account.profile);
    //     }
    //     accountObj[this.$t('userAccount.balance')] = '';
    //     if (account.balance !== undefined) {
    //       accountObj[this.$t('userAccount.balance')] = account.balance;
    //     }
    //     accountObj[this.$t('general.mac')] = '';
    //     if (account.macs && account.macs.length) {
    //       let macAddressesAsString = '';
    //       account.macs.forEach((mac) => {
    //         // for (const mac of account.macs) {
    //         macAddressesAsString += `${this.getFormatMac(mac)};  `;
    //       });
    //       accountObj[this.$t('general.mac')] = macAddressesAsString;
    //     }
    //     accountObj[this.$t('userAccount.name')] = '';
    //     if (account.name || account.surname) {
    //       accountObj[this.$t('userAccount.name')] = this.getFullName(account.name, account.surname);
    //     }
    //     accountObj[this.$t('userAccount.isUserAddedToBlacklist')] = this.$t('userAccount.isUserAddedToBlacklistNo');
    //     if (this.checkUserInBlacklist(account)) {
    //       accountObj[this.$t('userAccount.isUserAddedToBlacklist')] = this.$t('userAccount.isUserAddedToBlacklistYes');
    //     }
    //     accountObj[this.$t('userAccount.socialLinks')] = '—';
    //     accountObj[this.$t('userAccount.gender.type')] = '—';
    //     accountObj[this.$t('userAccount.birthday')] = '—';
    //     accountObj[this.$t('userAccount.city')] = '—';
    //     if (account.social_network !== null && Object.keys(account.social_network).length > 0) {
    //       accountObj[this.$t('userAccount.socialLinks')] = Object.keys(account.social_network).join(', ');
    //       // Имя из соц. сетей
    //       // let socialName = [];
    //       const socialGender = [];
    //       const socialBirthday = [];
    //       const socialCity = [];
    //
    //       Object.keys(account.social_network).forEach((el) => {
    //         const [day, month, year] = account.social_network[el].birthday.split(/[.-]/gi);
    //         const userAge = Math.floor((new Date() - new Date(year, month, day)) / 3600 / 24 / 365.25 / 1000);
    //
    //         // socialName.push(`${account.social_network[el].first_name} ${account.social_network[el].last_name}`);
    //         socialGender.push(
    //           account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'
    //         );
    //         socialBirthday.push(`${account.social_network[el].birthday} (${userAge})`);
    //         socialCity.push(account.social_network[el].city || '—');
    //       });
    //       // accountObj[this.$t('userAccount.nameFromSocial')] = socialName.join(', ');
    //       accountObj[this.$t('userAccount.gender.type')] = socialGender.join(', ') || '—';
    //       accountObj[this.$t('userAccount.birthday')] = socialBirthday.join(', ') || '—';
    //       accountObj[this.$t('userAccount.city')] = socialCity.join(', ') || '—';
    //     }
    //     result.push(accountObj);
    //   });
    //   return result;
    // },

    // formatPortalUserAccounts() {
    //   const result = [];
    //   this.userAccountsList.forEach((account) => {
    //     // for (const account of this.userAccountsList) {
    //     const accountObj = {};
    //     accountObj[this.$t('userAccount.username')] = '';
    //     if (account.identity) {
    //       accountObj[this.$t('userAccount.username')] = account.identity;
    //     }
    //     accountObj[this.$t('userAccount.profile')] = '';
    //     if (this.getProfileNameByID(account.profile)) {
    //       accountObj[this.$t('userAccount.profile')] = this.getProfileNameByID(account.profile);
    //     }
    //     accountObj[this.$t('userAccount.balance')] = '';
    //     if (account.balance !== undefined) {
    //       accountObj[this.$t('userAccount.balance')] = account.balance;
    //     }
    //     accountObj[this.$t('general.mac')] = '';
    //     if (account.macs && account.macs.length) {
    //       let macAddressesAsString = '';
    //       account.macs.forEach((mac) => {
    //         // for (const mac of account.macs) {
    //         macAddressesAsString += `${this.getFormatMac(mac)};  `;
    //       });
    //       accountObj[this.$t('general.mac')] = macAddressesAsString;
    //     }
    //     accountObj[this.$t('userAccount.name')] = '';
    //     if (account.name || account.surname) {
    //       accountObj[this.$t('userAccount.name')] = this.getFullName(account.name, account.surname);
    //     } else {
    //       accountObj[this.$t('userAccount.name')] = '—';
    //     }
    //     accountObj[this.$t('userAccount.isUserAddedToBlacklist')] = this.$t('userAccount.isUserAddedToBlacklistNo');
    //     if (this.checkUserInBlacklist(account)) {
    //       accountObj[this.$t('userAccount.isUserAddedToBlacklist')] = this.$t('userAccount.isUserAddedToBlacklistYes');
    //     }
    //     accountObj[this.$t('userAccount.socialLinks')] = '—';
    //     accountObj[this.$t('userAccount.gender.type')] = '—';
    //     accountObj[this.$t('userAccount.birthday')] = '—';
    //     accountObj[this.$t('userAccount.city')] = '—';
    //     if (account.social_network !== null && Object.keys(account.social_network).length > 0) {
    //       // accountObj[this.$t('userAccount.socialLinks')] = Object.keys(account.social_network).join(', ');
    //       accountObj[this.$t('userAccount.socialLinks')] = Object.keys(account.social_network)
    //         .map((network) => this.getStirngForSocialNetwork(network))
    //         .join('; ');
    //       // Имя из соц. сетей
    //       // let socialName = [];
    //       const socialGender = [];
    //       const socialBirthday = [];
    //       const socialCity = [];
    //
    //       Object.keys(account.social_network).forEach((el) => {
    //         const [day, month, year] = account.social_network[el].birthday.split(/[.-]/gi);
    //         const userAge = Math.floor((new Date() - new Date(year, month, day)) / 3600 / 24 / 365.25 / 1000);
    //
    //         // // socialName.push(`${account.social_network[el].first_name} ${account.social_network[el].last_name}`);
    //         // // account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'
    //         // socialGender.push( `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(account.social_network[el])}`
    //         //
    //         // );
    //         // // socialBirthday.push(`${account.social_network[el].birthday} (${userAge})`);
    //         // socialBirthday.push(
    //         //   `${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : this.$t(`userAccount.unset`)}`
    //         // );
    //         // // socialCity.push(account.social_network[el].city || '—');
    //         // socialCity.push(`${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].city || this.$t(`userAccount.unset`)}`);
    //
    //         if (Object.keys(account.social_network).length > 1) {
    //           // если несколько соц сетей, тогда подписываем перед значениями из какой они соц сети взяты (типа facebook: Moscow)
    //           socialGender.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(
    //               account.social_network[el]
    //             )}`
    //           );
    //           socialBirthday.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${
    //               account.social_network[el].birthday ?
    //                 `${account.social_network[el].birthday} (${userAge})` :
    //                 this.$t('userAccount.unset')
    //             }`
    //           );
    //           socialCity.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${
    //               account.social_network[el].city || this.$t('userAccount.unset')
    //             }`
    //           );
    //         } else {
    //           // а если одна - не подписываем
    //           socialGender.push(`${this.getStringForSocialNetworkSex(account.social_network[el])}`);
    //           socialBirthday.push(
    //             `${
    //               account.social_network[el].birthday ?
    //                 `${account.social_network[el].birthday} (${userAge})` :
    //                 this.$t('userAccount.unset')
    //             }`
    //           );
    //           socialCity.push(`${account.social_network[el].city || this.$t('userAccount.unset')}`);
    //         }
    //       });
    //       // accountObj[this.$t('userAccount.nameFromSocial')] = socialName.join(', ');
    //       accountObj[this.$t('userAccount.gender.type')] = socialGender.join('; ') || '—';
    //       accountObj[this.$t('userAccount.birthday')] = socialBirthday.join('; ') || '—';
    //       accountObj[this.$t('userAccount.city')] = socialCity.join('; ') || '—';
    //     }
    //     result.push(accountObj);
    //   });
    //   return result;
    // },

    // formatPortalUserAccounts() {
    //   const result = [];
    //   this.userAccountsList.forEach((account) => {
    //     // for (const account of this.userAccountsList) {
    //     const accountObj = {};
    //     accountObj[this.$t('userAccount.username')] = '';
    //     if (account.identity) {
    //       accountObj[this.$t('userAccount.username')] = account.identity;
    //     }
    //     accountObj[this.$t('userAccount.name')] = '';
    //     if (account.name || account.surname) {
    //       accountObj[this.$t('userAccount.name')] = this.getFullName(account.name, account.surname);
    //     } else {
    //       accountObj[this.$t('userAccount.name')] = '—';
    //     }
    //     accountObj[this.$t('userAccount.profile')] = '';
    //     if (this.getProfileNameByID(account.profile)) {
    //       accountObj[this.$t('userAccount.profile')] = this.getProfileNameByID(account.profile);
    //     }
    //     accountObj[this.$t('userAccount.balance')] = '';
    //     if (account.balance !== undefined) {
    //       accountObj[this.$t('userAccount.balance')] = account.balance;
    //     }
    //     accountObj[this.$t('general.mac')] = '';
    //     if (account.macs && account.macs.length) {
    //       let macAddressesAsString = '';
    //       account.macs.forEach((mac) => {
    //         // for (const mac of account.macs) {
    //         macAddressesAsString += `${this.getFormatMac(mac)};  `;
    //       });
    //       accountObj[this.$t('general.mac')] = macAddressesAsString;
    //     }
    //     accountObj[this.$t('userAccount.isUserAddedToBlacklist')] = this.$t('userAccount.isUserAddedToBlacklistNo');
    //     if (this.checkUserInBlacklist(account)) {
    //       accountObj[this.$t('userAccount.isUserAddedToBlacklist')] = this.$t('userAccount.isUserAddedToBlacklistYes');
    //     }
    //     accountObj[this.$t('userAccount.socialLinks')] = '—';
    //     accountObj[this.$t('userAccount.gender.type')] = '—';
    //     accountObj[this.$t('userAccount.birthday')] = '—';
    //     accountObj[this.$t('userAccount.city')] = '—';
    //     if (account.social_network !== null && Object.keys(account.social_network).length > 0) {
    //       // accountObj[this.$t('userAccount.socialLinks')] = Object.keys(account.social_network).join(', ');
    //       accountObj[this.$t('userAccount.socialLinks')] = Object.keys(account.social_network)
    //         .map((network) => this.getStirngForSocialNetwork(network))
    //         .join('; ');
    //       // Имя из соц. сетей
    //       // let socialName = [];
    //       const socialGender = [];
    //       const socialBirthday = [];
    //       const socialCity = [];
    //
    //       Object.keys(account.social_network).forEach((el) => {
    //         const [day, month, year] = account.social_network[el].birthday.split(/[.-]/gi);
    //         const userAge = Math.floor((new Date() - new Date(year, month, day)) / 3600 / 24 / 365.25 / 1000);
    //
    //         // // socialName.push(`${account.social_network[el].first_name} ${account.social_network[el].last_name}`);
    //         // // account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'
    //         // socialGender.push( `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(account.social_network[el])}`
    //         //
    //         // );
    //         // // socialBirthday.push(`${account.social_network[el].birthday} (${userAge})`);
    //         // socialBirthday.push(
    //         //   `${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : this.$t(`userAccount.unset`)}`
    //         // );
    //         // // socialCity.push(account.social_network[el].city || '—');
    //         // socialCity.push(`${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].city || this.$t(`userAccount.unset`)}`);
    //
    //         if (Object.keys(account.social_network).length > 1) {
    //           // если несколько соц сетей, тогда подписываем перед значениями из какой они соц сети взяты (типа facebook: Moscow)
    //           socialGender.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(
    //               account.social_network[el]
    //             )}`
    //           );
    //           socialBirthday.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${
    //               account.social_network[el].birthday ?
    //                 `${account.social_network[el].birthday} (${userAge})` :
    //                 this.$t('userAccount.unset')
    //             }`
    //           );
    //           socialCity.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${
    //               account.social_network[el].city || this.$t('userAccount.unset')
    //             }`
    //           );
    //         } else {
    //           // а если одна - не подписываем
    //           socialGender.push(`${this.getStringForSocialNetworkSex(account.social_network[el])}`);
    //           socialBirthday.push(
    //             `${
    //               account.social_network[el].birthday ?
    //                 `${account.social_network[el].birthday} (${userAge})` :
    //                 this.$t('userAccount.unset')
    //             }`
    //           );
    //           socialCity.push(`${account.social_network[el].city || this.$t('userAccount.unset')}`);
    //         }
    //       });
    //       // accountObj[this.$t('userAccount.nameFromSocial')] = socialName.join(', ');
    //       accountObj[this.$t('userAccount.gender.type')] = socialGender.join('; ') || '—';
    //       accountObj[this.$t('userAccount.birthday')] = socialBirthday.join('; ') || '—';
    //       accountObj[this.$t('userAccount.city')] = socialCity.join('; ') || '—';
    //     }
    //     result.push(accountObj);
    //   });
    //   return result;
    // },

    formatPortalUserAccounts() {
      const result = [];
      this.userAccountsList.forEach((account) => {
        // for (const account of this.userAccountsList) {
        const accountObj = {};
        accountObj[this.$t('userAccount.username')] = '';
        if (account.identity) {
          accountObj[this.$t('userAccount.username')] = account.identity;
        }
        accountObj[this.$t('userAccount.name')] = '';
        if (account.name || account.surname) {
          accountObj[this.$t('userAccount.name')] = this.getFullName(account.name, account.surname);
        } else {
          accountObj[this.$t('userAccount.name')] = '—';
        }
        accountObj[this.$t('userAccount.profile')] = '';
        if (this.getProfileNameByID(account.profile)) {
          accountObj[this.$t('userAccount.profile')] = this.getProfileNameByID(account.profile);
        }
        accountObj[this.$t('userAccount.balance')] = '';
        if (account.balance !== undefined) {
          accountObj[this.$t('userAccount.balance')] = account.balance;
        }
        accountObj[this.$t('general.mac')] = '';
        if (account.macs && account.macs.length) {
          let macAddressesAsString = '';
          account.macs.forEach((mac) => {
            // for (const mac of account.macs) {
            macAddressesAsString += `${this.getFormatMac(mac)};  `;
          });
          accountObj[this.$t('general.mac')] = macAddressesAsString;
        }
        accountObj[this.$t('userAccount.isUserAddedToBlacklist')] = this.$t('userAccount.isUserAddedToBlacklistNo');
        if (this.checkUserInBlacklist(account)) {
          accountObj[this.$t('userAccount.isUserAddedToBlacklist')] = this.$t('userAccount.isUserAddedToBlacklistYes');
        }
        accountObj[this.$t('userAccount.subscriptionTableTitle')] = '—';
        accountObj[this.$t('userAccount.socialLinks')] = '—';
        accountObj[this.$t('userAccount.gender.type')] = '—';
        accountObj[this.$t('userAccount.birthday')] = '—';
        accountObj[this.$t('userAccount.city')] = '—';
        if (account.social_network !== null && Object.keys(account.social_network).length > 0) {
          // accountObj[this.$t('userAccount.socialLinks')] = Object.keys(account.social_network).join(', ');
          accountObj[this.$t('userAccount.socialLinks')] = Object.keys(account.social_network)
            .map((network) => this.getStirngForSocialNetwork(network))
            .join('; ');
          // Имя из соц. сетей
          // let socialName = [];
          const socialGender = [];
          const socialBirthday = [];
          const socialCity = [];

          Object.keys(account.social_network).forEach((el) => {
            const [day, month, year] = account.social_network[el].birthday.split(/[.-]/gi);
            const userAge = Math.floor((new Date() - new Date(year, month, day)) / 3600 / 24 / 365.25 / 1000);

            // // socialName.push(`${account.social_network[el].first_name} ${account.social_network[el].last_name}`);
            // // account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'
            // socialGender.push( `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(account.social_network[el])}`
            //
            // );
            // // socialBirthday.push(`${account.social_network[el].birthday} (${userAge})`);
            // socialBirthday.push(
            //   `${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : this.$t(`userAccount.unset`)}`
            // );
            // // socialCity.push(account.social_network[el].city || '—');
            // socialCity.push(`${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].city || this.$t(`userAccount.unset`)}`);

            if (Object.keys(account.social_network).length > 1) {
              // если несколько соц сетей, тогда подписываем перед значениями из какой они соц сети взяты (типа facebook: Moscow)
              socialGender.push(
                `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(
                  account.social_network[el]
                )}`
              );
              socialBirthday.push(
                `${this.getStirngForSocialNetwork(el)}: ${
                  account.social_network[el].birthday ?
                    `${account.social_network[el].birthday} (${userAge})` :
                    this.$t('userAccount.unset')
                }`
              );
              socialCity.push(
                `${this.getStirngForSocialNetwork(el)}: ${
                  account.social_network[el].city || this.$t('userAccount.unset')
                }`
              );
            } else {
              // а если одна - не подписываем
              socialGender.push(`${this.getStringForSocialNetworkSex(account.social_network[el])}`);
              socialBirthday.push(
                `${
                  account.social_network[el].birthday ?
                    `${account.social_network[el].birthday} (${userAge})` :
                    this.$t('userAccount.unset')
                }`
              );
              socialCity.push(`${account.social_network[el].city || this.$t('userAccount.unset')}`);
            }
          });
          // accountObj[this.$t('userAccount.nameFromSocial')] = socialName.join(', ');
          accountObj[this.$t('userAccount.gender.type')] = socialGender.join('; ') || '—';
          accountObj[this.$t('userAccount.birthday')] = socialBirthday.join('; ') || '—';
          accountObj[this.$t('userAccount.city')] = socialCity.join('; ') || '—';
        }
        if (account.subscribe_id !== '') {
          // console.log('here')
          const subscriptionName = this.subscriptionObjectById(account.subscribe_id) ? this.subscriptionObjectById(account.subscribe_id).name : account.subscribe_id
          accountObj[this.$t('userAccount.subscriptionTableTitle')] = `${this.$t('userAccount.subscribed')}. ${subscriptionName} ${this.$t('userAccount.subscribedFromDate')} ${this.formatDateForTable(account.create_at_subscribe)} `
        }
        result.push(accountObj);
      });
      return result;
    },

    // formatPortalUserAccountToExcel() {
    //   const result = {};
    //   if (this.userAccountsList.length === 0) {
    //     return result;
    //   }
    //   result[this.$t('sidebar.accounts')] = [];
    //   const accountsToExcel = result[this.$t('sidebar.accounts')];
    //   accountsToExcel.push([
    //     this.$t('userAccount.username'),
    //     this.$t('userAccount.profile'),
    //     this.$t('userAccount.balance'),
    //     this.$t('general.mac'),
    //     this.$t('userAccount.name'),
    //     this.$t('userAccount.isUserAddedToBlacklist'),
    //     this.$t('userAccount.socialLinks'),
    //     this.$t('userAccount.gender.type'),
    //     this.$t('userAccount.birthday'),
    //     this.$t('userAccount.city')
    //     // this.$t('userAccount.nameFromSocial')
    //   ]);
    //   this.userAccountsList.forEach((account) => {
    //     // for (const account of this.userAccountsList) {
    //     let username = '';
    //     let profile = '';
    //     let balance = '';
    //     let mac = '';
    //     let name = '';
    //     let inBlackList = this.$t('userAccount.isUserAddedToBlacklistNo');
    //     let social = '—';
    //     // const socialName = [];
    //     const socialGender = [];
    //     const socialBirthday = [];
    //     const socialCity = [];
    //     if (account.identity) {
    //       username = account.identity;
    //     }
    //     if (this.getProfileNameByID(account.profile)) {
    //       profile = this.getProfileNameByID(account.profile);
    //     }
    //     if (account.balance !== undefined) {
    //       balance = account.balance;
    //     }
    //     if (account.macs.length) {
    //       let macAddressesAsString = '';
    //       account.macs.forEach((el) => {
    //         // for (const mac of account.macs) {
    //         macAddressesAsString += `${this.getFormatMac(el)};  `;
    //       });
    //       mac = macAddressesAsString;
    //     }
    //     if (account.name || account.surname) {
    //       name = this.getFullName(account.name, account.surname);
    //     }
    //     if (this.checkUserInBlacklist(account)) {
    //       inBlackList = this.$t('userAccount.isUserAddedToBlacklistYes');
    //     }
    //     if (account.social_network !== null && Object.keys(account.social_network).length > 0) {
    //       social = Object.keys(account.social_network).join(', ');
    //
    //       Object.keys(account.social_network).forEach((el) => {
    //         const [day, month, year] = account.social_network[el].birthday.split(/[.-]/gi);
    //         const userAge = Math.floor((new Date() - new Date(year, month, day)) / 3600 / 24 / 365.25 / 1000);
    //
    //         // Имя из соц сетей
    //         // socialName.push(`${account.social_network[el].first_name} ${account.social_network[el].last_name}`);
    //         socialGender.push(
    //           account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'
    //         );
    //         socialBirthday.push(
    //           account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : '—'
    //         );
    //         socialCity.push(account.social_network[el].city || '—');
    //       });
    //     }
    //     accountsToExcel.push([
    //       username,
    //       profile,
    //       balance,
    //       mac,
    //       name,
    //       inBlackList,
    //       social,
    //       socialGender.join(', ') || '—',
    //       socialBirthday.join(', ') || '—',
    //       socialCity.join(', ') || '—'
    //     ]);
    //   });
    //
    //   return result;
    // },

    // formatPortalUserAccountToExcel() {
    //   const result = {};
    //   if (this.userAccountsList.length === 0) {
    //     return result;
    //   }
    //   result[this.$t('sidebar.accounts')] = [];
    //   const accountsToExcel = result[this.$t('sidebar.accounts')];
    //   accountsToExcel.push([
    //     this.$t('userAccount.username'),
    //     this.$t('userAccount.profile'),
    //     this.$t('userAccount.balance'),
    //     this.$t('general.mac'),
    //     this.$t('userAccount.name'),
    //     this.$t('userAccount.isUserAddedToBlacklist'),
    //     this.$t('userAccount.socialLinks'),
    //     this.$t('userAccount.gender.type'),
    //     this.$t('userAccount.birthday'),
    //     this.$t('userAccount.city')
    //     // this.$t('userAccount.nameFromSocial')
    //   ]);
    //   this.userAccountsList.forEach((account) => {
    //     // for (const account of this.userAccountsList) {
    //     let username = '';
    //     let profile = '';
    //     let balance = '';
    //     let mac = '';
    //     let name = '';
    //     let inBlackList = this.$t('userAccount.isUserAddedToBlacklistNo');
    //     let social = '—';
    //     // const socialName = [];
    //     const socialGender = [];
    //     const socialBirthday = [];
    //     const socialCity = [];
    //     if (account.identity) {
    //       username = account.identity;
    //     }
    //     if (this.getProfileNameByID(account.profile)) {
    //       profile = this.getProfileNameByID(account.profile);
    //     }
    //     if (account.balance !== undefined) {
    //       balance = account.balance;
    //     }
    //     if (account.macs.length) {
    //       let macAddressesAsString = '';
    //       account.macs.forEach((el) => {
    //         // for (const mac of account.macs) {
    //         macAddressesAsString += `${this.getFormatMac(el)};  `;
    //       });
    //       mac = macAddressesAsString;
    //     }
    //     if (account.name || account.surname) {
    //       name = this.getFullName(account.name, account.surname);
    //     } else {
    //       name = '—';
    //     }
    //     if (this.checkUserInBlacklist(account)) {
    //       inBlackList = this.$t('userAccount.isUserAddedToBlacklistYes');
    //     }
    //     if (account.social_network !== null && Object.keys(account.social_network).length > 0) {
    //       // social = Object.keys(account.social_network).join(', ');
    //       social = Object.keys(account.social_network)
    //         .map((network) => this.getStirngForSocialNetwork(network))
    //         .join('; ');
    //
    //       Object.keys(account.social_network).forEach((el) => {
    //         const [day, month, year] = account.social_network[el].birthday.split(/[.-]/gi);
    //         const userAge = Math.floor((new Date() - new Date(year, month, day)) / 3600 / 24 / 365.25 / 1000);
    //
    //         // // Имя из соц сетей
    //         // // socialName.push(`${account.social_network[el].first_name} ${account.social_network[el].last_name}`);
    //         // // socialGender.push(
    //         // //   account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'
    //         // // );
    //         // socialGender.push( `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(account.social_network[el])}`)
    //         // // socialBirthday.push(
    //         // //   account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : '—'
    //         // // );
    //         // socialBirthday.push(
    //         //   `${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : this.$t(`userAccount.unset`)}`
    //         // );
    //         if (Object.keys(account.social_network).length > 1) {
    //           // если несколько соц сетей, тогда подписываем перед значениями из какой они соц сети взяты (типа facebook: Moscow)
    //           socialGender.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(
    //               account.social_network[el]
    //             )}`
    //           );
    //           socialBirthday.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${
    //               account.social_network[el].birthday ?
    //                 `${account.social_network[el].birthday} (${userAge})` :
    //                 this.$t('userAccount.unset')
    //             }`
    //           );
    //           socialCity.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${
    //               account.social_network[el].city || this.$t('userAccount.unset')
    //             }`
    //           );
    //         } else {
    //           // а если одна - не подписываем
    //           socialGender.push(`${this.getStringForSocialNetworkSex(account.social_network[el])}`);
    //           socialBirthday.push(
    //             `${
    //               account.social_network[el].birthday ?
    //                 `${account.social_network[el].birthday} (${userAge})` :
    //                 this.$t('userAccount.unset')
    //             }`
    //           );
    //           socialCity.push(`${account.social_network[el].city || this.$t('userAccount.unset')}`);
    //         }
    //       });
    //     }
    //     accountsToExcel.push([
    //       username,
    //       profile,
    //       balance,
    //       mac,
    //       name,
    //       inBlackList,
    //       social,
    //       socialGender.join('; ') || '—',
    //       socialBirthday.join('; ') || '—',
    //       socialCity.join('; ') || '—'
    //     ]);
    //   });
    //
    //   return result;
    // },

    // formatPortalUserAccountToExcel() {
    //   const result = {};
    //   if (this.userAccountsList.length === 0) {
    //     return result;
    //   }
    //   result[this.$t('sidebar.accounts')] = [];
    //   const accountsToExcel = result[this.$t('sidebar.accounts')];
    //   accountsToExcel.push([
    //     this.$t('userAccount.username'),
    //     this.$t('userAccount.name'),
    //     this.$t('userAccount.profile'),
    //     this.$t('userAccount.balance'),
    //     this.$t('general.mac'),
    //     this.$t('userAccount.isUserAddedToBlacklist'),
    //     this.$t('userAccount.socialLinks'),
    //     this.$t('userAccount.gender.type'),
    //     this.$t('userAccount.birthday'),
    //     this.$t('userAccount.city')
    //     // this.$t('userAccount.nameFromSocial')
    //   ]);
    //   this.userAccountsList.forEach((account) => {
    //     // for (const account of this.userAccountsList) {
    //     let username = '';
    //     let profile = '';
    //     let balance = '';
    //     let mac = '';
    //     let name = '';
    //     let inBlackList = this.$t('userAccount.isUserAddedToBlacklistNo');
    //     let social = '—';
    //     // const socialName = [];
    //     const socialGender = [];
    //     const socialBirthday = [];
    //     const socialCity = [];
    //     if (account.identity) {
    //       username = account.identity;
    //     }
    //     if (this.getProfileNameByID(account.profile)) {
    //       profile = this.getProfileNameByID(account.profile);
    //     }
    //     if (account.balance !== undefined) {
    //       balance = account.balance;
    //     }
    //     if (account.macs.length) {
    //       let macAddressesAsString = '';
    //       account.macs.forEach((el) => {
    //         // for (const mac of account.macs) {
    //         macAddressesAsString += `${this.getFormatMac(el)};  `;
    //       });
    //       mac = macAddressesAsString;
    //     }
    //     if (account.name || account.surname) {
    //       name = this.getFullName(account.name, account.surname);
    //     } else {
    //       name = '—';
    //     }
    //     if (this.checkUserInBlacklist(account)) {
    //       inBlackList = this.$t('userAccount.isUserAddedToBlacklistYes');
    //     }
    //     if (account.social_network !== null && Object.keys(account.social_network).length > 0) {
    //       // social = Object.keys(account.social_network).join(', ');
    //       social = Object.keys(account.social_network)
    //         .map((network) => this.getStirngForSocialNetwork(network))
    //         .join('; ');
    //
    //       Object.keys(account.social_network).forEach((el) => {
    //         const [day, month, year] = account.social_network[el].birthday.split(/[.-]/gi);
    //         const userAge = Math.floor((new Date() - new Date(year, month, day)) / 3600 / 24 / 365.25 / 1000);
    //
    //         // // Имя из соц сетей
    //         // // socialName.push(`${account.social_network[el].first_name} ${account.social_network[el].last_name}`);
    //         // // socialGender.push(
    //         // //   account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'
    //         // // );
    //         // socialGender.push( `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(account.social_network[el])}`)
    //         // // socialBirthday.push(
    //         // //   account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : '—'
    //         // // );
    //         // socialBirthday.push(
    //         //   `${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : this.$t(`userAccount.unset`)}`
    //         // );
    //         if (Object.keys(account.social_network).length > 1) {
    //           // если несколько соц сетей, тогда подписываем перед значениями из какой они соц сети взяты (типа facebook: Moscow)
    //           socialGender.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(
    //               account.social_network[el]
    //             )}`
    //           );
    //           socialBirthday.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${
    //               account.social_network[el].birthday ?
    //                 `${account.social_network[el].birthday} (${userAge})` :
    //                 this.$t('userAccount.unset')
    //             }`
    //           );
    //           socialCity.push(
    //             `${this.getStirngForSocialNetwork(el)}: ${
    //               account.social_network[el].city || this.$t('userAccount.unset')
    //             }`
    //           );
    //         } else {
    //           // а если одна - не подписываем
    //           socialGender.push(`${this.getStringForSocialNetworkSex(account.social_network[el])}`);
    //           socialBirthday.push(
    //             `${
    //               account.social_network[el].birthday ?
    //                 `${account.social_network[el].birthday} (${userAge})` :
    //                 this.$t('userAccount.unset')
    //             }`
    //           );
    //           socialCity.push(`${account.social_network[el].city || this.$t('userAccount.unset')}`);
    //         }
    //       });
    //     }
    //     accountsToExcel.push([
    //       username,
    //       name,
    //       profile,
    //       balance,
    //       mac,
    //       inBlackList,
    //       social,
    //       socialGender.join('; ') || '—',
    //       socialBirthday.join('; ') || '—',
    //       socialCity.join('; ') || '—'
    //     ]);
    //   });
    //
    //   return result;
    // },

    formatPortalUserAccountToExcel() {
      const result = {};
      if (this.userAccountsList.length === 0) {
        return result;
      }
      result[this.$t('sidebar.accounts')] = [];
      const accountsToExcel = result[this.$t('sidebar.accounts')];
      accountsToExcel.push([
        this.$t('userAccount.username'),
        this.$t('userAccount.name'),
        this.$t('userAccount.profile'),
        this.$t('userAccount.balance'),
        this.$t('general.mac'),
        this.$t('userAccount.isUserAddedToBlacklist'),
        this.$t('userAccount.subscriptionTableTitle'),
        this.$t('userAccount.socialLinks'),
        this.$t('userAccount.gender.type'),
        this.$t('userAccount.birthday'),
        this.$t('userAccount.city')
        // this.$t('userAccount.nameFromSocial')
      ]);
      this.userAccountsList.forEach((account) => {
        // for (const account of this.userAccountsList) {
        let username = '';
        let profile = '';
        let balance = '';
        let mac = '';
        let name = '';
        let inBlackList = this.$t('userAccount.isUserAddedToBlacklistNo');
        let subscription = '-';
        let social = '—';
        // const socialName = [];
        const socialGender = [];
        const socialBirthday = [];
        const socialCity = [];
        if (account.identity) {
          username = account.identity;
        }
        if (this.getProfileNameByID(account.profile)) {
          profile = this.getProfileNameByID(account.profile);
        }
        if (account.balance !== undefined) {
          balance = account.balance;
        }
        if (account.macs.length) {
          let macAddressesAsString = '';
          account.macs.forEach((el) => {
            // for (const mac of account.macs) {
            macAddressesAsString += `${this.getFormatMac(el)};  `;
          });
          mac = macAddressesAsString;
        }
        if (account.name || account.surname) {
          name = this.getFullName(account.name, account.surname);
        } else {
          name = '—';
        }
        if (this.checkUserInBlacklist(account)) {
          inBlackList = this.$t('userAccount.isUserAddedToBlacklistYes');
        }
        if (account.social_network !== null && Object.keys(account.social_network).length > 0) {
          // social = Object.keys(account.social_network).join(', ');
          social = Object.keys(account.social_network)
            .map((network) => this.getStirngForSocialNetwork(network))
            .join('; ');

          Object.keys(account.social_network).forEach((el) => {
            const [day, month, year] = account.social_network[el].birthday.split(/[.-]/gi);
            const userAge = Math.floor((new Date() - new Date(year, month, day)) / 3600 / 24 / 365.25 / 1000);

            // // Имя из соц сетей
            // // socialName.push(`${account.social_network[el].first_name} ${account.social_network[el].last_name}`);
            // // socialGender.push(
            // //   account.social_network[el].sex ? this.$t(`userAccount.gender.${account.social_network[el].sex}`) : '—'
            // // );
            // socialGender.push( `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(account.social_network[el])}`)
            // // socialBirthday.push(
            // //   account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : '—'
            // // );
            // socialBirthday.push(
            //   `${this.getStirngForSocialNetwork(el)}: ${account.social_network[el].birthday ? `${account.social_network[el].birthday} (${userAge})` : this.$t(`userAccount.unset`)}`
            // );
            if (Object.keys(account.social_network).length > 1) {
              // если несколько соц сетей, тогда подписываем перед значениями из какой они соц сети взяты (типа facebook: Moscow)
              socialGender.push(
                `${this.getStirngForSocialNetwork(el)}: ${this.getStringForSocialNetworkSex(
                  account.social_network[el]
                )}`
              );
              socialBirthday.push(
                `${this.getStirngForSocialNetwork(el)}: ${
                  account.social_network[el].birthday ?
                    `${account.social_network[el].birthday} (${userAge})` :
                    this.$t('userAccount.unset')
                }`
              );
              socialCity.push(
                `${this.getStirngForSocialNetwork(el)}: ${
                  account.social_network[el].city || this.$t('userAccount.unset')
                }`
              );
            } else {
              // а если одна - не подписываем
              socialGender.push(`${this.getStringForSocialNetworkSex(account.social_network[el])}`);
              socialBirthday.push(
                `${
                  account.social_network[el].birthday ?
                    `${account.social_network[el].birthday} (${userAge})` :
                    this.$t('userAccount.unset')
                }`
              );
              socialCity.push(`${account.social_network[el].city || this.$t('userAccount.unset')}`);
            }
          });
        }

        if (account.subscribe_id !== '') {
          // console.log('here')
          const subscriptionName = this.subscriptionObjectById(account.subscribe_id) ? this.subscriptionObjectById(account.subscribe_id).name : account.subscribe_id
          subscription = `${this.$t('userAccount.subscribed')}. ${subscriptionName} ${this.$t('userAccount.subscribedFromDate')} ${this.formatDateForTable(account.create_at_subscribe)} `
        }


        accountsToExcel.push([
          username,
          name,
          profile,
          balance,
          mac,
          inBlackList,
          subscription,
          social,
          socialGender.join('; ') || '—',
          socialBirthday.join('; ') || '—',
          socialCity.join('; ') || '—'
        ]);
      });

      return result;
    },

    addToBlackList(profileId, accountId) {
      portalUserAccountsService.actionWithList(this, 'add_bl', { profileId, accountId });
    },
    deleteFromBlackList(profileId, accountId) {
      portalUserAccountsService.actionWithList(this, 'del_bl', { profileId, accountId });
    },
    addToAccessList(profileId, accountId) {
      portalUserAccountsService.actionWithList(this, 'add_al', { profileId, accountId });
    },
    deleteFromAccessList(profileId, accountId) {
      portalUserAccountsService.actionWithList(this, 'del_al', { profileId, accountId });
    },
    checkUserInBlacklist(user) {
      const thisUserProfile = this.portalProfilesList.filter((profile) => profile.id === user.profile)[0];
      const blacklistArray =
        thisUserProfile && thisUserProfile.black_list ? Object.keys(thisUserProfile.black_list) : [];
      if (blacklistArray.includes(user.id)) return true;
      return false;
    },
    checkUserInAccesslist(user) {
      const thisUserProfile = this.portalProfilesList.filter((profile) => profile.id === user.profile)[0];
      const accesslistArray =
        thisUserProfile && thisUserProfile.access_list ? Object.keys(thisUserProfile.access_list) : [];
      if (accesslistArray.includes(user.id)) return true;
      return false;
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      portalUserAccountsService.getUserAccounts(this, { query: true });
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMoreAccounts = true;
      this.$store.commit('portalUserAccountsListClean');
    },

    openModal(isNew, account) {
      this.$validator.reset();
      this.isModalOpen = true;
      if (isNew) {
        this.isNew = true;
        this.account = { ...this.newAccount };
        watcher.reset();
      } else {
        this.isNew = false;
        this.account = { ...account };
        this.getUserAccountByIds(account);
        this.$nextTick(() => {
          watcher.reset();
        });
      }
      // watcher.reset();
    },
    getUserAccountByIds(account) {
      portalUserAccountsService.getUserAccountById(this, account);
    },
    handleSave() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.isNew) {
            this.createUserAccount(this.account);
          } else {
            this.updateUserAccount(this.account);
          }
        }
      });
    },
    cancelEditMode() {
      this.isModalOpen = false;
      this.isDeleteOpen = false;
      this.isCloseModalOpen = false;
      // NOTE: reset store to false
      this.$store.commit('setPortalUserTransactionsList', false);

      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    correctValuesTypes(account) {
      return {
        ...account,
        balance: parseInt(account.balance, 10)
      };
    },
    updateUserAccount(account) {
      portalUserAccountsService.updateUserAccount(this, this.correctValuesTypes({ ...account }));
    },
    createUserAccount(account) {
      portalUserAccountsService.createUserAccount(this, this.correctValuesTypes(helpers.removeID({ ...account })));
    },
    deleteUserAccount() {
      portalUserAccountsService.deleteUserAccount(this, this.account);
    },
    getFullName(name, surname) {
      if (name && surname) {
        return `${name} ${surname}`;
      }
      if (!name && !surname) {
        return '';
      }
      if (!name || !surname) {
        return name ? `${name}` : `${surname}`;
      }
      return '';
    },
    getProfileNameByID(id) {
      if (this.portalProfilesList) {
        const result = this.portalProfilesList.find((item) => item.id === id);
        if (result) {
          return result.name;
        }
        return '';
      }
      return '';
    },
    getFormatMac: helpers.getFormatMac,
    // getWithQuery() {
    //   this.clearQuery();
    //   if (
    //     this.filters.q['visits.all'].$eq === '' ||
    //     this.filters.q['visits.all'].$gt === '' ||
    //     this.filters.q['visits.all'].$lt === ''
    //   ) {
    //     this.filters.q = { ...this.filters.q, 'visits.all': {} };
    //   }
    //
    //   portalUserAccountsService.getUserAccounts(this, { query: true });
    // },
    getWithQuery() {
      this.clearQuery();
      if (
        this.filters.q['visits.all'].$eq === '' ||
        this.filters.q['visits.all'].$gt === '' ||
        this.filters.q['visits.all'].$lt === ''
      ) {
        this.filters.q = { ...this.filters.q, 'visits.all': {} };
      }
      // console.log(JSON.parse(JSON.stringify(this.filters)))
      this.$nextTick(()=>{
        portalUserAccountsService.getUserAccounts(this, { query: true });
      })
    },
    handleCloseDeleteDialog() {
      this.isDeleteOpen = false;
    },
    // createSocialLink(id, social) {
    //   switch (social) {
    //     case 'vk':
    //       return new URL(id, 'https://vk.com');
    //     case 'fb':
    //       return new URL(id, 'https://facebook.com/profile.php?id=');
    //     default:
    //       return false;
    //   }
    // }
    createSocialLink(id, social) {
      try {
        switch (social) {
          case 'vk':
            return `https://vk.com/id${id}`;
          case 'fb':
            return `https://facebook.com/profile.php?id=${id}`;
          case 'facebook':
            return `https://facebook.com/profile.php?id=${id}`;
          default:
            return false;
        }
      } catch (e) {
        return false;
      }
    }
  },
  watch: {
    account: {
      deep: true,
      handler() {
        watcher.tick();
      }
    },
    'account.date_of_birth': function () {
      if (this.account.date_of_birth === null) this.account.date_of_birth = '';
      const [day, month, year] = this.account.date_of_birth.split('-');

      this.account.date_of_birth_struct = {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day, 10)
      };
      // console.log(this.account.date_of_birth_struct);
    }
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    ...featureFlagsMapGetters(['isContentPortalPaidInternetEnabled']),
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    subscriptionsList() {
      return this.subscriptionsAndTariffsList.filter((tariffOrSubscription)=>{
        return tariffOrSubscription.type === 'subscription';
      });
    },
    // isFiltersActive() {
    //   return this.filters.limit !== 30 || this.filters.sort_by !== 'profile' ||
    //     this.filters.sort_order !== 1 || this.filters.search !== '' ||
    //     (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
    //     typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 ) ||
    //     this.filters.q.city !== '' || this.filters.q.home_town !== '' || this.filters.q.sex !== '' || this.filters.q.social_network !== '' ||
    //     this.filters.q['date_of_birth_struct.year'] !== '' || this.filters.q['visits.all'] !== '' || this.subscribe_id !== 'unset' ||
    //     (Array.isArray(this.filters.profiles) && this.filters.profiles.length !== 0)
    // },
    isFiltersActive() {
      return this.filters.limit !== 30 || this.filters.sort_by !== 'profile' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 ) ||
        this.filters.q.city !== '' || this.filters.q.home_town !== '' || this.filters.q.sex !== '' || this.filters.q.social_network !== '' ||
        this.filters.q["date_of_birth_struct.year"] !== '' || this.filters.q.subscribe_id !== 'unset' ||
        (Array.isArray(this.filters.profiles) && this.filters.profiles.length !== 0) ||
        (Object.keys(this.filters.q["visits.all"]) && Object.keys(this.filters.q["visits.all"]).length !== 0)
    },
    subscriptionsListById() {
      const result = {};
      if (this.subscriptionsList.length) {
        this.subscriptionsList.forEach((subscription) => {
          result[subscription.id] = JSON.parse(JSON.stringify(subscription));
        })
      }
      return result;
    },
    isDisabledWithChilds() {
      if (this.filterLocation.location.id === '') {
        return true;
      }
      return false;
    },
    selectedProfileInMultiselectForRequest() {
      const result = [];
      if (this.selectedProfileInMultiselect.length === 0) {
        return result;
      }
      for (const profile of this.selectedProfileInMultiselect) {
        result.push(profile.id);
      }
      return result;
    },
    formattedQSocialNetwork() {
      if (this.filters.q.social_network === 'vk') {
        return { 'social_network.vk': { $exists: true } };
      }
      if (this.filters.q.social_network === 'facebook') {
        return { 'social_network.facebook': { $exists: true } };
      }
      if (this.filters.q.social_network === 'instagram') {
        return { 'social_network.instagram': { $exists: true } };
      }
      return {};
    },
    formattedQ() {
      const copy = { ...this.filters.q, ...this.formattedQSocialNetwork };

      delete copy.social_network;
      if (!copy.home_town) {
        delete copy.home_town;
      }
      if (!Object.keys(copy['visits.all']).length) {
        if (!copy['visits.all'].$gt) {
          delete copy['visits.all'].$gt;
        }
        if (!copy['visits.all'].$lt) {
          delete copy['visits.all'].$lt;
        }
        if (!copy['visits.all'].$eq) {
          delete copy['visits.all'].$eq;
        }
        delete copy['visits.all'];
      }

      if (!copy.city) {
        delete copy.city;
      }
      if (!copy.sex) {
        delete copy.sex;
      }
      if (!copy['date_of_birth_struct.year']) {
        delete copy['date_of_birth_struct.year'];
      }
      if (copy.subscribe_id === 'unset') {
        delete copy.subscribe_id;
      }
      return copy;
    },
    GENDERS() {
      return GENDERS;
    },
    SOCIALS() {
      return SOCIALS;
    },
    tablePaginationOptions() {
      const options = [
        { value: 'profile', label: this.$t('userAccount.profile') },
        { value: 'identity', label: this.$t('userAccount.username') },
        { value: 'name', label: this.$t('userAccount.name') },
        { value: 'visits.all', label: this.$t('general.filtersVisitsAll') },
        { value: 'subscribe_id', label: this.$t('general.filtersSubscriptionStatus') },
        { value: 'create_at_subscribe', label: this.$t('general.filtersSubscriptionAtTime') }
      ];
      if (this.showBalanceAndTransactions) {
        options.push({ value: 'balance', label: this.$t('userAccount.balance') });
      }
      return options;
    },
    showBalanceAndTransactions() {
      return this.isContentPortalPaidInternetEnabled;
    },
    muteEdit() {
      return this.$store.state.mutePortalUserAccountEdit;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    userAccountsList() {
      return this.$store.state.portalUserAccountsList;
    },
    portalProfilesList() {
      return this.$store.state.portalProfilesList;
    },
    portalProfilesListById() {
      const result = {};
      for (const profile of this.portalProfilesList) {
        result[profile.id] = profile;
      }
      return result;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    locationsListById() {
      const result = {};
      for (const location of this.locationsList) {
        result[location.id] = location;
      }
      return result;
    },
    portalProfilesListForFilter() {
      const list = this.$store.state.portalProfilesList || [];
      if (list.length && list[0].id) {
        list.unshift({ name: this.$t('portalStats.allProfiles') });
      }
      return list;
    },
    locationsListForFilter() {
      const result = [];
      let baseLocation = {};
      const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
      // const locations = JSON.parse(JSON.stringify(this.$store.state.locationsList));
      // const locations = helpers.sortLocationsList(JSON.parse(JSON.stringify(this.$store.state.locationsList)));
      const locations = JSON.parse(JSON.stringify(this.$store.getters.locationsListSorted));
      // console.log(userBaseLocation)
      locations.forEach((location) => {
        if (location.id === userBaseLocation) {
          // this.filters.location = { name: location.name, id: location.id };
          baseLocation = { name: location.name, id: location.id };
          // console.log(baseLocation)
          return;
        }
        result.push({ name: location.name, id: location.id });
      });
      result.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      result.unshift(baseLocation);
      result.unshift({ name: this.$t('portalStats.allLocations') });
      // console.log(result)
      return result;
    },
    portalProfilesListForFilterMultiselect() {
      const list =
        this.$store.state.portalProfilesList && this.$store.state.portalProfilesList.length ?
          this.$store.state.portalProfilesList :
          [];
      const listForMultiselect = JSON.parse(JSON.stringify(list));
      if (listForMultiselect.length && listForMultiselect[0].id) {
      } else {
        listForMultiselect.shift();
      }
      listForMultiselect.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return listForMultiselect;
    },
    isUserAccountsLoading() {
      return this.$store.state.loadingPortalUserAccounts || this.$store.state.loadingPortalProfiles ||
        this.isGetSubscriptionsFromUserAccountsLoading;
    },
  },
  // created() {
  //   this.$store.commit('portalProfilesListClean');
  //   this.clearQuery();
  //   // прилетает из метода goToAccountsWithIdsList компонента PortalProfilesNew
  //   if (this.$route.params.accountsIdsList && this.$route.params.accountsIdsList.length > 0) {
  //     const { accountsIdsList } = this.$route.params;
  //     portalUserAccountsService.getUserAccounts(this, { query: true, ids: accountsIdsList });
  //   } else {
  //     portalUserAccountsService.getUserAccounts(this, { query: true });
  //   }
  //   // portalUserAccountsService.getUserAccounts(this, { query: true });
  //   portalProfilesService.getPortalProfiles(this);
  //   this.getSubscriptionsAndTariffs();
  // },

  created() {
    this.$store.commit('portalProfilesListClean');
    this.clearQuery();
    // прилетает из метода goToAccountsWithIdsList компонента PortalProfilesNew
    if (this.$route.params.accountsIdsList && this.$route.params.accountsIdsList.length > 0) {
      const {accountsIdsList} = this.$route.params;
      portalUserAccountsService.getUserAccounts(this, {query: true, ids: accountsIdsList});
    } else {
      let inHeaderLocationSelectedApplying = true;
      if (this.$store.state.inHeaderLocationSelectModeEnabled &&
        inHeaderLocationSelectedApplying) {
        let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
        let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
        if (!locationForFiltering || locationForFiltering === '') {
          // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
          locationForFiltering = 'All locations';
        }
        if (locationForFiltering === 'All locations') {
          this.filters.location.data = {};
          this.filters.location.with_childs = true;
        } else {
          this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
          this.filters.location.with_childs = withChildsForFiltering
        }
      }

      portalUserAccountsService.getUserAccounts(this, {query: true});
    }
    // portalUserAccountsService.getUserAccounts(this, { query: true });
    portalProfilesService.getPortalProfiles(this);
    this.getSubscriptionsAndTariffs();
  },

  updated() {
    // portalProfilesService.getPortalProfiles(this);
  }
};
</script>

<style lang="scss">
.loader-backdrop-portaluseraccounts:after {
  position: absolute;
  top: 30px;
}
.half-width {
  width: 46%;
}
.text-center {
  text-align: center;
}
.cool-modern-selector input {
  display: none !important;
}

.cool-modern-selector input[type='radio'] + label,
.cool-modern-selector input[type='checkbox'] + label {
  display: inline-block;
  padding-right: 20px;
  border-radius: 6px;
  background: #dddddd;
  color: #939393;
  height: 35.5px;
  line-height: 35.5px;
  text-align: center;
  cursor: pointer;
  /* margin-top: 3px;
  margin-bottom: 3px; */
  user-select: none;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cool-modern-selector input[type='radio']:checked + label,
.cool-modern-selector input[type='checkbox']:checked + label {
  background: #4dbd74;
  color: #ffffff;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
</style>
<style lang="scss" scoped>
.disbale-with-childs {
  cursor: not-allowed;
}

.custom-width-for-location-filter-left {
  width: 85% !important;
}
.custom-width-for-location-filter-right {
  width: 15% !important;
}
.location-filter-with-child-caption {
  max-height: 1em;
  min-width: 200px;
}
.custom-width-for-profile-select-in-portal-accounts {
  width: 50%;
}

.bottom-margin-for-multiselect {
  margin-bottom: 0.65rem;
}
.social-networks-icons-in-table {
  margin-right: 5px;
}
  .subscription-info-in-users-table{
    font-size: 0.8em;
  }
</style>
