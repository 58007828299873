<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    :width="widthPX"
    :height="heightPX"
    viewBox="0 0 257.7 257.7"
  >
    <path
      d="M248.1,4.6H18.8A14.25,14.25,0,0,0,4.6,18.8V248.1a14.19,14.19,0,0,0,14.2,14.2H248.1a14.19,14.19,0,0,0,14.2-14.2V18.8A14.19,14.19,0,0,0,248.1,4.6ZM222.3,71.9H201.7c-16.2,0-19.3,7.6-19.3,18.9v24.8h38.5l-5,38.9H182.4v99.8H142.3V154.5H108.7V115.6h33.6V86.9c0-33.3,20.3-51.4,50-51.4a272.24,272.24,0,0,1,30,1.5Z"
      transform="translate(-4.6 -4.6)"
      :fill="colorComputed"
    />
  </svg>
  <!--  <svg-->
  <!--    viewBox="0 0 512 512"-->
  <!--    y="0px"-->
  <!--    x="0px"-->
  <!--    version="1.1"-->
  <!--    :style="{ height: heightPX, width: widthPX }"-->
  <!--    :fill="colorComputed"-->
  <!--  >-->
  <!--    <g id="g6">-->
  <!--      <g id="g4">-->
  <!--        <path-->
  <!--          id="path2"-->
  <!--          d="M256,375.333c-5.523,0-10,4.477-10,10c0,0.013,0,0.027,0,0.04s0,0.027,0,0.04v0.08c0,5.523,4.477,10,10,10s10-4.477,10-10    c0-0.014,0-0.026,0-0.04c0-0.014,0-0.026,0-0.04c0-0.014,0-0.027,0-0.04s0-0.027,0-0.04C266,379.81,261.523,375.333,256,375.333z"-->
  <!--        />-->
  <!--      </g>-->
  <!--    </g>-->
  <!--    <g id="g12">-->
  <!--      <g id="g10">-->
  <!--        <path-->
  <!--          id="path8"-->
  <!--          d="M414,0H98C43.962,0,0,43.962,0,98v316c0,54.038,43.962,98,98,98h158c5.523,0,10-4.477,10-10v-77.5c0-5.523-4.477-10-10-10    s-10,4.477-10,10V492H98c-43.009,0-78-34.991-78-78V98c0-43.009,34.991-78,78-78h316c43.009,0,78,34.991,78,78v316    c0,43.009-34.991,78-78,78h-64V315.501h57.981c5.136,0,9.436-3.89,9.95-8.999l7.191-71.455c0.283-2.813-0.637-5.613-2.533-7.709    c-1.896-2.096-4.591-3.292-7.417-3.292H350v-37.521c0-6.791,2.163-9.316,7.981-9.316h56.742c5.523,0,10-4.477,10-10v-69.66    c0-5.523-4.477-10-10-10h-74.517c-42.223,0-64.615,18.546-75.964,34.104c-15.873,21.758-18.243,47.454-18.243,60.834v42.007h-49    c-5.523,0-10,4.477-10,10V305.5c0,5.523,4.477,10,10,10h49V344c0,5.523,4.477,10,10,10s10-4.477,10-10v-38.5    c0-5.523-4.477-10-10-10h-49v-51.007h49c5.523,0,10-4.477,10-10v-52.007c0-22.532,7.231-74.938,74.207-74.938h64.517v49.66    h-46.742c-12.908,0-27.981,7.678-27.981,29.316v47.521c0,5.523,4.477,10,10,10h64.115l-5.178,51.455H340c-5.523,0-10,4.477-10,10    V502c0,5.523,4.477,10,10,10h74c54.038,0,98-43.962,98-98V98C512,43.962,468.038,0,414,0z"-->
  <!--        />-->
  <!--      </g>-->
  <!--    </g>-->
  <!--  </svg>-->
</template>

<script>
export default {
  name: 'IconFacebook',
  props: {
    height: { type: Number, default: 24 },
    width: { type: Number, default: 24 },
    color: { type: String, default: '#FFFFFF' }
  },
  computed: {
    heightPX() {
      return `${this.height}px`;
    },
    widthPX() {
      return `${this.width}px`;
    },
    colorComputed() {
      return this.color;
    }
  }
};
</script>

<style lang="scss">
.icon {
  margin: 0 4px;
}
</style>
