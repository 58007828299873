<template>
  <div class="interface-block mb-1">
    <div class="setting-block-header" @click.prevent.self="showTransactions = !showTransactions">
      <div class="setting-block-status" @click.prevent="showTransactions = !showTransactions">
        <p class="h6 m-h pr-h setting-block-title">
          {{ $t('userAccount.transactions') }}
          <info :content="$t('userAccount.transactionsInfo')" />
        </p>
      </div>

      <div class="setting-block-buttons d-flex">
        <!-- <div class="d-flex justify-content-end">
          <WButton primary
            customClass="mr-1"
            @click.stop="getUserTransactionHistory"
          >
            {{ $t('userAccount.getTransactions', { num: filters.limit }) }}
          </WButton>

          <input
            class="col-2 form-control"
            type="number"
            name="transactions-limit"
            id="transactions-limit"
            placeholder="Type limit"
            v-model.number="filters.limit"
          />
        </div> -->
        <WButton link
          customClass="new-modern-style-btn-other-btn"
          @click="showTransactions = !showTransactions"
        >
          <i class="icon-arrow-down" v-if="showTransactions"></i>
          <i class="icon-arrow-left" v-else></i>
        </WButton>
      </div>
    </div>

    <div v-if="showTransactions">
      <div v-if="userTransactionsList && userTransactionsList.length" class="text-right my-h">
        {{ $t('userAccount.history30d') }}
      </div>
      <table v-if="userTransactionsList && userTransactionsList.length" class="table table-hover">
        <thead>
          <tr>
            <th scope="col" class="text-nowrap align-middle">
              {{ $t('userAccount.createdOn') }}
              <!-- <i v-if="filters.sort_order === -1" class="fa fa-sort-amount-desc" @click="sort(1, 'create_at')" />
              <i v-if="filters.sort_order === 1" class="fa fa-sort-amount-asc" @click="sort(-1, 'create_at')" /> -->
            </th>
            <th scope="col" class="text-nowrap align-middle">
              {{ $t('userAccount.type') }}
            </th>
            <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.amount') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in userTransactionsList" :key="transaction.id">
            <td class="align-middle">
              <span v-if="transaction.create_at">
                {{ moment(transaction.create_at * 1000).format('lll') }}
              </span>
              <EmptyCellValue v-else />
            </td>
            <td class="align-middle">
              <h6>
                <span class="badge" :class="{ 'badge-success': transaction.fill, 'badge-danger': !transaction.fill }">
                  {{ additionOrPurchase(transaction.fill) }}
                </span>
              </h6>
            </td>
            <td class="align-middle">
              <span v-if="transaction.balance">
                {{ transaction.balance }}
              </span>
              <EmptyCellValue v-else />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="text-center my-h">{{ $t('userAccount.noTransactions') }}</div>
    </div>
    <!-- </transition> -->
  </div>
</template>
<script>
import portalUserAccountsService from '../../services/portalUserAccountsService';
import EmptyCellValue from '../empty-cell-value.vue';
import Info from '../Universal/info-icon.vue';

export default {
  name: 'AccountTransactions',
  components: { EmptyCellValue, Info },
  props: { account: { type: Object } },
  data() {
    return {
      showTransactions: false,
      filters: {
        limit: 5,
        offset: null,
        sort_order: -1,
        sort_by: 'create_at'
      }
    };
  },
  computed: {
    userTransactionsList() {
      return this.$store.state.portalUserTransactionsList;
    }
  },
  // watch: {
  //   'filters.sort_order': function () {
  //     this.getUserTransactionHistory();
  //     if (!this.showTransactions) this.showTransactions = true;
  //   }
  // },
  methods: {
    additionOrPurchase(value) {
      return value ? this.$t('userAccount.addition') : this.$t('userAccount.purchase');
    },
    getUserTransactionHistory() {
      portalUserAccountsService.getUserTransactionHistory(this, this.account);
    }
    // sort(order, sortBy) {
    //   this.filters.sort_order = order;
    //   this.filters.sort_by = sortBy;
    // }
  },
  created() {
    this.getUserTransactionHistory();
  }
};
</script>
