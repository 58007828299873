<template>
  <!-- <div class="col-12"> -->
  <div class="interface-block mb-1">
    <div class="setting-block-header" @click.prevent.self="showSessions = !showSessions">
      <div class="setting-block-status" @click.prevent="showSessions = !showSessions">
        <p class="h6 m-h pr-h setting-block-title">{{ $t('portalSessions.sessions') }}</p>
      </div>

      <div class="setting-block-buttons d-flex">
        <div class="d-flex justify-content-end">
          <WButton primary
            customClass="mr-1"
            @click.stop="getSessions"
          >
            {{ $t('portalSessions.getSessions', { num: filters.limit }) }}
          </WButton>
          <input
            class="col-2 form-control"
            type="number"
            name="sessions-limit"
            id="sessions-limit"
            placeholder="Type limit"
            v-model.number="filters.limit"
          />
        </div>
        <WButton link
          customClass="new-modern-style-btn-other-btn"
          @click="showSessions = !showSessions"
        >
          <i class="icon-arrow-down" v-if="showSessions"></i>
          <i class="icon-arrow-left" v-else></i>
        </WButton>
      </div>
    </div>
    <div v-if="showSessions" class="col-12 table-responsive mt-1">
      <!-- {{ portalSessionsList }} -->
      <loader-spinner-small centered v-if="isPortalSessionListLoading"></loader-spinner-small>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col" class="text-nowrap align-middle">
              {{ $t('portalSessions.browser') }}
            </th>
            <th scope="col" class="text-nowrap align-middle">
              {{ $t('portalSessions.create') }}
              <i v-if="filters.sort_order === -1" class="fa fa-sort-amount-desc" @click="sort(1, 'create_at')" />
              <i v-if="filters.sort_order === 1" class="fa fa-sort-amount-asc" @click="sort(-1, 'create_at')" />
            </th>
            <th scope="col" class="text-nowrap align-middle">{{ $t('portalSessions.os') }}</th>
            <th scope="col" class="text-nowrap align-middle">{{ $t('portalSessions.mac') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="session in portalSessionsList" :key="session.id">
            <td class="align-middle">
              <span v-if="session.ua.name">
                {{ session.ua.name }}
                <i
                  v-if="session.ua.type"
                  class="fa"
                  v-tooltip="{ content: $t(`portalSessions.ua.${session.ua.type}`) }"
                  :class="{
                    'fa-desktop': session.ua.type === 'desktop',
                    'fa-mobile': session.ua.type === 'mobile',
                    'fa-tablet': session.ua.type === 'tablet',
                    'fa-wrench': session.ua.type === 'bot'
                  }"
                ></i>
              </span>
              <EmptyCellValue v-else />
            </td>
            <td class="align-middle">
              <span v-if="session.create">
                {{ moment(session.create).format('lll') }}
              </span>
              <EmptyCellValue v-else />
            </td>
            <td class="align-middle">
              <span v-if="session.ua.os" v-tooltip="{ content: session.ua.os_version }">
                {{ session.ua.os }}
              </span>
              <EmptyCellValue v-else />
            </td>
            <td class="align-middle">
              <span v-if="session.mac">
                {{ session.mac }}
              </span>
              <EmptyCellValue v-else />
            </td>
          </tr>
          <tr v-if="!portalSessionsList.length">
            <td colspan="4" class="no-data-row">
              <span>{{ $t('aps.noDataToDisplay') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import portalSessionsService from '../../services/portalSessionsService';
import EmptyCellValue from '../empty-cell-value.vue';

export default {
  name: 'AccountSessions',
  components: { EmptyCellValue },
  props: {
    account: { type: Object }
  },
  data() {
    return {
      showSessions: false,
      filters: {
        limit: 5,
        offset: null,
        sort_by: 'create_at',
        sort_order: -1 /* 1 for ascending, -1 for descending */
      }
    };
  },
  computed: {
    portalSessionsList() {
      return this.$store.state.portalSessionsList;
    },
    isPortalSessionListLoading() {
      return this.$store.state.loadingPortalSessions;
    }
  },
  watch: {
    'filters.sort_order': function () {
      this.getSessions();
    }
  },
  methods: {
    getSessions() {
      portalSessionsService.getSessions(this, { query: true });
      if (!this.showSessions) this.showSessions = true;
    },
    sort(order, sortBy) {
      this.filters.sort_order = order;
      this.filters.sort_by = sortBy;
    }
  },
  created() {
    portalSessionsService.getSessions(this, { query: true });
  },
  beforeDestroy() {
    this.$store.commit('setPortalSessionsList', false);
  }
};
</script>
